import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import axios from "axios";
import { KokattoGetCustomerServiceResponse } from "./responses/KokattoGetCustomerServiceResponse";
import { KokattoGetCustomerServiceRequest } from "@/serviceClients/requests/KokattoGetCustomerServiceRequest";
import { KokattoPostActivatePlayModeCustomerServiceRequest } from "./requests/KokattoPostActivatePlayModeCustomerServiceRequest";
import { KokattoActivatePlayModeCustomerServiceResponse } from "./responses/KokattoActivatePlayModeCustomerServiceResponse";
export class KokattoCustomerServiceServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL ?? "";
    }
  }

  async getCustomerService(
    request?: KokattoGetCustomerServiceRequest
  ): Promise<KokattoGetCustomerServiceResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/customerService`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: {
          ...request,
        },
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async activatePlaymodeCustomerService(
    request?: KokattoPostActivatePlayModeCustomerServiceRequest
  ): Promise<
    KokattoActivatePlayModeCustomerServiceResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/customerService`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getCustomerServiceDetails(): Promise<
    KokattoActivatePlayModeCustomerServiceResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/customerService/me`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }
}
