import { ActionTree, GetterTree, MutationTree } from "vuex";

import { KokattoInboxServiceClient } from "@/serviceClients/KokattoInboxServiceClient";

import { KokattoGetMacrosListRequest } from "@/serviceClients/requests/KokattoGetMacrosListRequest";
import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import {
  KokattoGetMacrosListResponse,
  KokattoGetMacrosListPaginatedResponse,
} from "@/serviceClients/responses/KokattoGetMacrosListResponse";

import {
  State,
  AsyncState,
  MacrosListState,
  MacrosListStateData,
  GetMacrosListActionPayload,
  onApplyMacroActionPayload,
} from "./types";

const DEFAULT_ASYNC_STATE: AsyncState = {
  loading: false,
  data: null,
  error: null,
};

const DEFAULT_STATE: State = {
  macros: { ...DEFAULT_ASYNC_STATE }, // retrieve the macros list using this state
};

const state: State = { ...DEFAULT_STATE };

const getters: GetterTree<State, any> = {
  getMacrosListState: (state: State): MacrosListState => state.macros,
};

const mutations: MutationTree<State> = {
  beginGetMacrosList(state: State, isNextFetch: boolean) {
    if (isNextFetch) {
      state.macros = { ...state.macros, loading: true };
      return;
    }

    state.macros = { ...DEFAULT_ASYNC_STATE, loading: true };
  },
  successGetMacrosList(
    state: State,
    {
      macrosListStateData,
      isNextFetch = false,
    }: {
      macrosListStateData: MacrosListStateData;
      isNextFetch: boolean;
    }
  ) {
    if (isNextFetch) {
      // it's safe to assume that we already have existing 'macros' data if the 'isNextFetch = true'
      const currentData = state.macros
        .data as KokattoGetMacrosListPaginatedResponse;
      const currentContents = currentData.contents;

      // it's also safe to assume that we'll always have a new 'macros' data if this mutation is called with 'isNextFetch = true'
      const incomingData =
        macrosListStateData as KokattoGetMacrosListPaginatedResponse;
      const incomingContents = incomingData.contents;

      state.macros = {
        ...DEFAULT_ASYNC_STATE,
        data: {
          ...currentData,
          contents: [...currentContents, ...incomingContents],
        },
      };
      return;
    }
    state.macros = { ...DEFAULT_ASYNC_STATE, data: macrosListStateData };
  },
  errorGetMacrosList(state: State, error: Error) {
    state.macros = { ...DEFAULT_ASYNC_STATE, error };
  },
};

const actions: ActionTree<State, any> = {
  // GET Macros List
  async getMacrosList(
    { commit, rootGetters },
    payload: GetMacrosListActionPayload
  ): Promise<void> {
    try {
      const kokattoInboxServiceClient = new KokattoInboxServiceClient({
        token: rootGetters.getKokattoTokenAccess,
      });

      const isNextFetch = payload.pageNumber > 1;
      commit("beginGetMacrosList", isNextFetch);

      const requestParam: KokattoGetMacrosListRequest = {
        search: payload.search,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      };
      const response: KokattoGetMacrosListResponse | KokattoErrorResponse =
        await kokattoInboxServiceClient.getMacrosList(requestParam);
      const { macros } = response as KokattoGetMacrosListResponse;

      commit("successGetMacrosList", {
        macrosListStateData: macros,
        isNextFetch,
      });
    } catch (error) {
      console.error("[ERROR] Something went wrong in getMacrosList()", error);
      commit("errorGetMacrosList", error);
      throw error;
    }
  },

  // subscribe this action to immediately get the latest applied macro object in any component
  onApplyMacro(context, payload: onApplyMacroActionPayload | null) {
    // leave the action body empty
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
