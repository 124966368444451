import axios from "axios";
import { KokattoServiceClient } from "./KokattoServiceClient";
import { KoalaPlusBalanceResponse } from "@/serviceClients/responses/KoalaPlusBalanceResponse";
import { KoalaPlusGetBalanceByClientIdRequest } from "./requests/KoalaPlusGetBalanceByClientIdRequest";
import { KoalaPlusGetMutationListByClientIdRequest } from "./requests/KoalaPlusGetMutationListByClientIdRequest";
import { KoalaPlusGetBillingTransactionByClientIdRequest } from "./requests/KoalaPlusGetBillingTransactionByClientIdRequest";
import { KoalaPlusPostChatCreditTopUpRequest } from "./requests/KoalaPlusPostChatCreditTopUpRequest";
import { KoalaPlusPostChatCreditTopUpResponse } from "./responses/KoalaPlusPostChatCreditTopUpResponse";
import { KoalaPlusPostPaymentFeeCalculationResponse } from "./responses/KoalaPlusPostPaymentFeeCalculationResponse";
import { KoalaPlusMutationListResponse } from "./responses/KoalaPlusMutationListResponse";
import { KoalaPlusBillingTransactionResponse } from "./responses/KoalaPlusBillingTransactionResponse";
import { KoalaPlusDownloadMutationListResponse } from "./responses/KoalaPlusDownloadMutationListResponse";
import { KoalaPlusChargeChatCreditRequest } from "./requests/KoalaPlusChargeChatCreditRequest";
import { KoalaPlusPostPaymentFeeCalculationRequest } from "./requests/KoalaPlusPostPaymentFeeCalculationRequest";
import { KoalaChatroomChargeStatusResponse } from "./responses/KoalaChatroomChargeStatusResponse";

export class KoalaPlusChatCreditServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOALA_PLUS ?? "";
    }
  }

  async getBalanceByClientId(
    request: KoalaPlusGetBalanceByClientIdRequest
  ): Promise<KoalaPlusBalanceResponse> {
    const url = `${this.baseUrl}/wallet/byClientId`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getMutationListByClientId(
    request: KoalaPlusGetMutationListByClientIdRequest
  ): Promise<KoalaPlusMutationListResponse> {
    const url = `${this.baseUrl}/chat-credit/list`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getBillingTransactionListByClientId(
    request: KoalaPlusGetBillingTransactionByClientIdRequest
  ): Promise<KoalaPlusBillingTransactionResponse> {
    const url = `${this.baseUrl}/billing-transaction/list`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async downloadMutationListByClientId(
    request: KoalaPlusGetMutationListByClientIdRequest
  ): Promise<KoalaPlusDownloadMutationListResponse> {
    const url = `${this.baseUrl}/chat-credit/download`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async postChatCreditTopUp(
    request: KoalaPlusPostChatCreditTopUpRequest
  ): Promise<KoalaPlusPostChatCreditTopUpResponse> {
    const url = `${this.baseUrl}/billing-transaction`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async chargeCsReplyChatCredit(
    request: KoalaPlusChargeChatCreditRequest
  ): Promise<any> {
    const url = `${this.baseUrl}/chat-credit/charge`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getChatroomChargeStatus(
    chatRoomId: string,
    clientId: string,
    mediaPlatform: string
  ): Promise<KoalaChatroomChargeStatusResponse> {
    const request = {
      clientId: clientId,
      chatRoomId: chatRoomId,
      mediaPlatform: mediaPlatform,
    };

    const url = `${this.baseUrl}/chat-room`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async postPaymentFeeCalculation(
    request: KoalaPlusPostPaymentFeeCalculationRequest
  ): Promise<KoalaPlusPostPaymentFeeCalculationResponse> {
    const url = `${this.baseUrl}/billing-transaction/calculate-payment-fee`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }
}
