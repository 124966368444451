export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getSellerIdFromProcatToken(procatToken: string) {
  const parsedJwt = parseJwt(procatToken);
  return parsedJwt.account.sellerId;
}

export function getClientTimezoneFromJwt(token: string) {
  const parsedJwt = parseJwt(token);
  return parsedJwt.sub.clientTimezone;
}

export function getFeaturesAccessFromJwt(token: string) {
  const parsedJwt = parseJwt(token);
  return parsedJwt.sub.featuresAccessPermission;
}

export function getUserDetailFromJwt(token: string) {
  const parsedJwt = parseJwt(token);
  return parsedJwt.sub;
}

export function getKoalaUserDetail(token: string) {
  const parsedJwt = parseJwt(token);
  return parsedJwt;
}
