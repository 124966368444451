import axios from "axios";
import { KoalaErrorResponse } from "@/serviceClients/responses/KoalaErrorResponse";
import { KoalaPlusAccountActivityRequest } from "@/serviceClients/requests/KoalaPlusAccountActivityRequest";
import { KoalaPlusAccountActivityResponse } from "@/serviceClients/responses/KoalaPlusAccountActivityResponse";
import { KoalaServiceClient } from "@/serviceClients/KoalaServiceClient";

export class KoalaPlusAccountActivityServiceClient extends KoalaServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOALA_PLUS ?? "";
    }
  }

  async createOrUpdateAccountActivity(
    request: KoalaPlusAccountActivityRequest
  ): Promise<KoalaPlusAccountActivityResponse | KoalaErrorResponse> {
    const url = `${this.baseUrl}/account-activity`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<KoalaPlusAccountActivityResponse>(
        url,
        request,
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data;
    });
  }

  async getAccountActivity(
    request: KoalaPlusAccountActivityRequest
  ): Promise<KoalaPlusAccountActivityResponse | KoalaErrorResponse> {
    const url = `${this.baseUrl}/account-activity`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get<KoalaPlusAccountActivityResponse>(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async deleteAccountActivity(
    request: KoalaPlusAccountActivityRequest
  ): Promise<KoalaPlusAccountActivityResponse | KoalaErrorResponse> {
    const url = `${this.baseUrl}/account-activity/delete`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<KoalaPlusAccountActivityResponse>(
        url,
        request,
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data;
    });
  }
}
