import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  agentInstanceId: string;
};

const state: State = {
  agentInstanceId: "",
};

const getters: GetterTree<State, any> = {
  getAgentInstanceId: (state) => state.agentInstanceId,
};

const mutations: MutationTree<State> = {
  updateAgentInstanceId(state, instanceId: string) {
    state.agentInstanceId = instanceId;
  },
};

const actions: ActionTree<State, any> = {
  setAgentInstanceId(context, item: object) {
    context.commit("updateAgentInstanceId", item);
  },
};

const CallCenterStore = { state, getters, mutations, actions };

export default CallCenterStore;
