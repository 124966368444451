import { ActionTree, GetterTree, MutationTree } from "vuex";
import { isKokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";
import {
  State,
  GetCustomFieldHeadersPayload,
  TicketChatListCustomFieldState,
} from "./types";
import { KokattoGetTicketFieldsResponse } from "@/serviceClients/responses/KokattoGetTicketFieldsResponse";
import { HeaderColumn } from "@/components/customerService/TicketChatList/types";
import {
  HEADER_COLUMN_SELECTIONS,
  DEFAULT_HEADER_COLUMNS,
} from "@/components/customerService/TicketChatList/constants";

// Default async state for custom field headers
const DEFAULT_ASYNC_STATE: TicketChatListCustomFieldState = {
  loading: false,
  data: {
    contents: [],
    page: 0,
    totalPages: 0,
    totalRecord: 0,
  },
  error: null,
};

// Initial state
const defaultState: State = {
  customFieldHeaders: { ...DEFAULT_ASYNC_STATE },
  headersList: [...HEADER_COLUMN_SELECTIONS],
  selectedHeaders: [],
};
const state: State = JSON.parse(JSON.stringify({ ...defaultState }));

// Vuex getters
const getters: GetterTree<State, any> = {
  getCustomFieldHeadersState: (state: State) => state.customFieldHeaders,
  getSelectedHeadersState: (state: State) => state.selectedHeaders,
  getHeadersList: (state: State) => state.headersList,
};

// Mutation helpers
function resetSelectedHeaders(headers: HeaderColumn[]) {
  headers.forEach((header) => {
    header.selectedIndex = -1;
  });
}

function mapApiResponseToHeaders(contentResponse: any[]): HeaderColumn[] {
  return contentResponse.map((ticketField) => ({
    id: ticketField.id,
    key: ticketField.title,
    name: ticketField.title,
    selectedIndex: -1,
  }));
}

function updateSelectionState(
  list: HeaderColumn[],
  selectedHeaders: HeaderColumn[]
) {
  list.forEach((item) => {
    item.selectedIndex = selectedHeaders.findIndex((sh) => {
      if (sh.id) return sh.id === item.id;
      else return sh.name === item.name;
    });
  });
}

// Vuex mutations
const mutations: MutationTree<State> = {
  resetStateToDefault(state: State) {
    Object.assign(state, JSON.parse(JSON.stringify({ ...defaultState })));
  },

  clearSelectedHeaders(state: State) {
    state.selectedHeaders = [];
    resetSelectedHeaders(state.headersList);
    resetSelectedHeaders(state.customFieldHeaders.data.contents);
  },

  beginGetCustomFieldHeaders(state: State) {
    state.customFieldHeaders.loading = true;
  },

  successGetCustomFieldHeaders(
    state: State,
    { fields }: KokattoGetTicketFieldsResponse
  ) {
    const { page, totalPages, totalRecord, contents } = fields;

    state.customFieldHeaders.data = {
      page,
      totalPages,
      totalRecord: totalRecord || 0,
      contents: [
        ...state.customFieldHeaders.data.contents,
        ...mapApiResponseToHeaders(contents),
      ],
    };
    state.customFieldHeaders.loading = false;
    state.customFieldHeaders.error = null;
  },

  errorGetCustomFieldHeaders(state: State, error: Error) {
    state.customFieldHeaders.loading = false;
    state.customFieldHeaders.error = error;
  },

  selectedHeadersChanged(state: State, header: HeaderColumn) {
    const headerIndex = state.selectedHeaders.findIndex((selectedHeader) =>
      header.id
        ? selectedHeader.id === header.id
        : selectedHeader.name === header.name
    );

    if (headerIndex >= 0) {
      state.selectedHeaders.splice(headerIndex, 1);
      header.selectedIndex = -1;
    } else {
      state.selectedHeaders.push(header);
    }

    updateSelectionState(state.headersList, state.selectedHeaders);
    updateSelectionState(
      state.customFieldHeaders.data.contents,
      state.selectedHeaders
    );
  },

  setDefaultSelectedHeaders(state: State) {
    DEFAULT_HEADER_COLUMNS.forEach((header, index) => {
      header.selectedIndex = index;
      state.selectedHeaders.push(header);
    });
  },
};

// Vuex actions
const actions: ActionTree<State, any> = {
  async getCustomFieldHeaders(
    { commit, rootGetters },
    payload: GetCustomFieldHeadersPayload
  ) {
    try {
      const kokattoTicketServiceClient = new KokattoTicketServiceClient({
        token: rootGetters.getKokattoTokenAccess,
      });

      commit("beginGetCustomFieldHeaders");

      const responseData = await kokattoTicketServiceClient.getTicketFields(
        payload
      );

      if (isKokattoErrorResponse(responseData)) {
        throw new Error("Fetch Ticket Fields Error");
      }

      commit("successGetCustomFieldHeaders", responseData);
    } catch (error) {
      commit("errorGetCustomFieldHeaders", error);
      console.error("[ERROR] getTicketList() - TicketView module", error);
    }
  },
};

// Vuex store module export
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
