const id = {
  noBroadcastHistoryTitle: "Belum ada riwayat email",
  noBroadcastHistoryContent:
    "Info broadcast email yang kamu kirim akan muncul di halaman ini.",
  historyBroadcastCampaignCode: "Kode campaign",
  historyBroadcastTimeBroadcast: "Tanggal broadcast",
  historyBroadcastTemplateName: "Nama template",
  historyBroadcastBroadcastTotal: "Total penerima",
  historyBroadcastAccountSender: "Akun pengirim",
  historyBroadcastStatus: "Status",
  historyBroadcastAction: "Aksi",
  historyBroadcastStatusPartially: "Partially failed",
  historyBroadcastStatusDelivered: "Delivered",
  historyBroadcastStatusFailed: "Failed",
  historyBroadcastStatusInProcess: "In process",
  historyBroadcastErrorGeneral:
    "Gagal mendapatkan data broadcast. Silahkan coba beberapa saat lagi.",
  historyBroadcastViewDetail: "Lihat rincian",
  historyBroadcastEmailCC: "Penerima tambahan",
  historyBroadcastDate: "Tanggal broadcast",
  historyBroadcastCategory: "Kategori",
  historyBroadcastSubCategory: "Subkategori",
  historyBroadcastDetailSender: "Akun pengirim",
  historyBroadcastReadTitle: "Total dibaca",
  historyBroadcastFailedTitle: "Gagal terkirim",
  historyBroadcastPreviousPageButton: "Kembali",
  historyBroadcastDetailEmail: "Detail email",
  labelFilterEmailReceiver: "Cari email penerima",
  labelFilterByStatus: "Tampilkan status",
  historyBroadcastEmailReceiver: "Email penerima",
  historyBroadcastSendDate: "Tanggal dikirim",
  historyBroadcastReceivedDate: "Tanggal diterima",
  historyBroadcastReadDate: "Tanggal dibaca",
  historyBroadcastNotificationErrorMessage:
    "Terjadi masalah di email pelanggan: kotak masuk email penuh atau email tidak valid",
  historyBroadcastFailedToSend: " email gagal dikirim ke penerima",
  labelFindReceiver: "Cari penerima",
  statusFilterSend: "Berhasil",
  statusFilterFailed: "Gagal",
  historyBroadcastNotFound: "Detail broadcast tidak ditemukan!",
  historyBroadcastErrorDownload:
    "Gagal download data broadcast. Silakan coba lagi.",
};

const en = {
  noBroadcastHistoryTitle: "No history yet",
  noBroadcastHistoryContent:
    "Email broadcast info that you've sent will appear here.",
  historyBroadcastCampaignCode: "Campaign code",
  historyBroadcastTimeBroadcast: "Broadcast Time",
  historyBroadcastTemplateName: "Template Name",
  historyBroadcastBroadcastTotal: "Total Recipients",
  historyBroadcastAccountSender: "Sender",
  historyBroadcastStatus: "Status",
  historyBroadcastAction: "Action",
  historyBroadcastStatusPartially: "Partially failed",
  historyBroadcastStatusDelivered: "Delivered",
  historyBroadcastStatusFailed: "Failed",
  historyBroadcastStatusInProcess: "In process",
  historyBroadcastErrorGeneral:
    "Failed get broadcast data. Please try again later.",
  historyBroadcastViewDetail: "View detail",
  historyBroadcastEmailCC: "Additional recipients",
  historyBroadcastDate: "Broadcast date",
  historyBroadcastCategory: "Category",
  historyBroadcastSubCategory: "Subcategory",
  historyBroadcastDetailSender: "Sender account",
  historyBroadcastReadTitle: "Total read",
  historyBroadcastFailedTitle: "Failed to send",
  historyBroadcastPreviousPageButton: "Back",
  historyBroadcastDetailEmail: "Email detail",
  labelFilterEmailReceiver: "Find email receiver",
  labelFilterByStatus: "Show status",
  historyBroadcastEmailReceiver: "Email receiver",
  historyBroadcastSendDate: "Send date",
  historyBroadcastReceivedDate: "Received date",
  historyBroadcastReadDate: "Read date",
  historyBroadcastNotificationErrorMessage:
    "There was an issue with the customer's email: inbox is full or the email is invalid",
  historyBroadcastFailedToSend:
    " emails failed to be delivered to the recipients",
  labelFindReceiver: "Find receiver",
  statusFilterSend: "Completed",
  statusFilterFailed: "Failed",
  historyBroadcastNotFound: "Broadcast detail not found!",
  historyBroadcastErrorDownload:
    "Failed to download broadcast list, Please try again.",
};

export default { id, en };
