const id = {
  autoAssignActivateButton: "Auto Assign",
  autoAssignStopButton: "Stop",
  autoAssignSkipButton: "Berikutnya",
  autoAssignEmptyStateTitle: "Belum ada chat untuk ditangani",
  autoAssignDeactivePlayModeDescription:
    "Aktifkan mode Auto Assign agar chat bisa ditugaskan kepada kamu secara otomatis.",
  autoAssignActiveModeDescription:
    "Auto Assign sedang aktif. Chat ditugaskan otomatis untukmu.",
  autoAssignSkipDialogTitle: "Konfirmasi penanganan chat",
  autoAssignSkipDialogLabel: "Keterangan",
  autoAssignSkipDialogPlaceholder:
    "Contoh: Sudah follow up, Menunggu balasan pelanggan,dll.",
  autoAssignSkipDialogFooter:
    "Setelah konfirmasi, kamu akan diarahkan ke chat berikutnya yang sudah ditugaskan kepada kamu.",
  autoAssignSkipDialogButton: "Konfirmasi",
  autoAssignStopDialogTitle: "Hentikan mode Auto Assign?",
  autoAssignStopDialogDescription:
    "Perubahan yang kamu lakukan dalam chat saat ini tidak akan tersimpan dan chat tidak lagi ditugaskan secara otomatis.",
  autoAssignStopDialogConfirmButton: "Ya, hentikan",
  autoAssignStopDialogCancelButton: "Batalkan",
  autoAssignEmptyStateDescription:
    "Chat dari pelanggan yang ditugaskan untukmu  akan muncul di sini.",
  autoAssignFailedActivateSnackBar:
    "Gagal mengaktifkan auto assign. Silahkan coba lagi.",
  autoAssignFailedDeactivateSnackBar:
    "Gagal menonaktifkan auto assign. Silahkan coba lagi",
  autoAssignLoadingTicketStateTitle: "Menyiapkan chat berikutnya...",
  autoAssignLoadingTicketStateDescription:
    "Kamu akan menangani chat masuk yang sudah ditugaskan secara otomatis.",
};

const en = {
  autoAssignActivateButton: "Auto Assign",
  autoAssignStopButton: "Stop",
  autoAssignSkipButton: "Next",
  autoAssignEmptyStateTitle: "No chat to handle yet",
  autoAssignDeactivePlayModeDescription:
    "Use Auto Assign mode to get chat assigned to you automatically.",
  autoAssignActiveModeDescription:
    "Auto Assign mode is on. Chat is automatically assigned to you.",
  autoAssignSkipDialogTitle: "Confirm handling chat",
  autoAssignSkipDialogLabel: "Notes",
  autoAssignSkipDialogPlaceholder:
    "Example: Done follow up, Waiting for customer’s repy,etc.",
  autoAssignSkipDialogFooter:
    "After confirming, you will be directed to the next chat that has been assigned to you.",
  autoAssignSkipDialogButton: "Confirm",
  autoAssignStopDialogTitle: "Stop Auto Assign mode?",
  autoAssignStopDialogDescription:
    "All changes you have made in the current chat will be discarded and chat is no longer automatically assigned to you.",
  autoAssignStopDialogConfirmButton: "Yes, stop",
  autoAssignStopDialogCancelButton: "Cancel",
  autoAssignEmptyStateDescription:
    "Chat from customer that is assigned to you will be available here.",
  autoAssignFailedActivateSnackBar:
    "Failed to activate auto assign. Please try again later",
  autoAssignFailedDeactivateSnackBar:
    "Failed to deactivate auto assign. Please try again later",
  autoAssignLoadingTicketStateTitle: "Preparing the next chat...",
  autoAssignLoadingTicketStateDescription:
    "You will handle incoming chat that is assigned to you automatically.",
};

export default { id, en };
