import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  selectedChannelFilter: object | null;
  storeFilter: object | null;
  // chatIdDownloadedAttachments: Set<string>;
  chatIdDownloadedAttachments: string;
  transactionForm: object | null;
  customerPhoneNumber: string;
  csChatRole: string; // ChatRole: CS, CS-SPV, SPV
};

const state: State = {
  selectedChannelFilter: null,
  storeFilter: null,
  // chatIdDownloadedAttachments: new Set(),
  chatIdDownloadedAttachments: "",
  transactionForm: null,
  customerPhoneNumber: "",
  csChatRole: "",
};

const getters: GetterTree<State, any> = {
  getSelectedChannelFilter: (state) => state.selectedChannelFilter,
  getStoreFilter: (state) => state.storeFilter,
  getDownloadedAttachments: (state) => state.chatIdDownloadedAttachments,
  getTransactionForm: (state) => state.transactionForm,
  getCustomerPhoneNumber: (state) => state.customerPhoneNumber,
  getCsChatRole: (state) => state.csChatRole,
};

const mutations: MutationTree<State> = {
  updateSelectedChannelFilter(state, channel: object) {
    state.selectedChannelFilter = channel;
  },
  updateStoreFilter(state, store: object) {
    state.storeFilter = store;
  },
  resetDownloadedAttachmentAfterRefresh(state, attachmentChatroomList: string) {
    state.chatIdDownloadedAttachments = attachmentChatroomList;
  },
  updateTransactionForm(state, transaction: object) {
    state.transactionForm = transaction;
  },
  updateCustomerPhoneNumber(state, customerPhoneNumber: string) {
    state.customerPhoneNumber = customerPhoneNumber;
  },
  updateDownloadedAttachments(state, payload: any) {
    const chatroomId = payload.chatroomId;
    const messageId = payload.messageId;
    const status = payload.status;
    const progress = payload.progress;
    let currentList: any;
    try {
      currentList = JSON.parse(state.chatIdDownloadedAttachments);
    } catch (err) {
      currentList = {};
    }
    const chatRoomDownloadedList: any[] = currentList[chatroomId] || [];
    const indexOfMessage = chatRoomDownloadedList.findIndex(
      (messageObj: any) => {
        return messageObj.messageId == messageId;
      }
    );
    if (indexOfMessage < 0) {
      const data: any = {
        messageId: messageId,
        status: 0,
        progress: 0,
      };
      if (status == "downloading") {
        data.status += 10;
      } else if (status == "downloaded") {
        data.status += 1;
        data.progress = 100;
      } else if (status == "progress") {
        data.progress = progress;
      }
      chatRoomDownloadedList.push(data);
    } else {
      const messageObj = chatRoomDownloadedList[indexOfMessage];
      if (status == "downloading") {
        messageObj.status += 10;
      } else if (status == "downloaded") {
        messageObj.status -= 10;
        if (messageObj.status % 10 == 0) {
          messageObj.status += 1;
        }
        messageObj.progress = 100;
      } else if (status == "progress") {
        messageObj.progress = progress;
      } else {
        messageObj.status -= 10;
      }
    }
    currentList[chatroomId] = chatRoomDownloadedList;
    const stringifyList: string = JSON.stringify(currentList);
    state.chatIdDownloadedAttachments = stringifyList;
  },
  updateCsChatRole(state, payload: string) {
    state.csChatRole = payload;
  },
};

const actions: ActionTree<State, any> = {
  setSelectedChannelFilter(context, channel: object) {
    context.commit("updateSelectedChannelFilter", channel);
  },
  clearSelectedChannelFilter(context) {
    context.commit("updateSelectedChannelFilter", null);
  },
  setStoreFilter(context, store: object) {
    context.commit("updateStoreFilter", store);
  },
  clearStoreFilter(context) {
    context.commit("updateStoreFilter", null);
  },
  resetDownloadedAttachmentAfterRefresh(
    context,
    attachmentChatroomList: string
  ) {
    context.commit(
      "resetDownloadedAttachmentAfterRefresh",
      attachmentChatroomList
    );
  },
  updateDownloadedAttachments(context, payload: any) {
    context.commit("updateDownloadedAttachments", payload);
  },
  clearDownloadedAttachments(context) {
    context.commit("updateDownloadedAttachments", "");
  },
  setTransactionForm(context, transaction: object) {
    context.commit("updateTransactionForm", transaction);
  },
  clearTransactionForm(context) {
    context.commit("updateTransactionForm", null);
  },
  updateCustomerPhoneNumber(context, customerPhoneNumber: string) {
    context.commit("updateCustomerPhoneNumber", customerPhoneNumber);
  },
  clearCustomerPhoneNumber(context) {
    context.commit("updateCustomerPhoneNumber", "");
  },
  setCsChatRole(context, chatRole: string) {
    context.commit("updateCsChatRole", chatRole);
  },
};

const CustomerService = { state, getters, mutations, actions };

export default CustomerService;
