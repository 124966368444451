import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KoalaBaseResponse } from "@/serviceClients/responses/KoalaBaseResponse";
import { KoalaTrackerServiceClient } from "@/serviceClients/KoalaTrackerServiceClient";
import { KoalaTrackerRequest } from "@/serviceClients/requests/KoalaTrackerRequest";
import store from "@/store";
import { KoalaTrackerResponse } from "@/serviceClients/responses/KoalaTrackerResponse";

export abstract class TrackerFunnelHelper {
  public static async submitPageVisitation(reqParameter: KoalaTrackerRequest) {
    try {
      let reponseApi: KoalaTrackerResponse;

      if (
        store.getters.getKoalaTokenAccess &&
        store.getters.getKoalaTokenAccess !== ""
      ) {
        const serviceClient = new KoalaTrackerServiceClient({
          token: store.getters.getKoalaTokenAccess || "",
        });
        reponseApi = await serviceClient.postTracker(reqParameter);
      } else {
        const serviceClient = new KoalaTrackerServiceClient();
        reponseApi = await serviceClient.postTracker(reqParameter);
      }

      if (reponseApi && reponseApi.statusCode !== 200) {
        console.log("ERROR ::", reponseApi);
      }
    } catch (e) {
      console.log("ERROR ::", e);
    }
  }
}
