import { ActionTree, GetterTree, MutationTree } from "vuex";
import { TicketForm } from "@/models/TicketFormModel";

interface IField {
  [key: string]: any;
}

interface IForm {
  ticketForm: TicketForm | null;
  chatPriority: string | { key: string; value: any };
  chatCategory: string[];
  emailCC: string[];
}

interface State {
  form: IForm | null;
  customFields: IField | null;
}

const DEFAULT_FORM_STATE: IForm = {
  ticketForm: {} as TicketForm,
  chatPriority: "",
  chatCategory: [],
  emailCC: [],
};

const DEFAULT_FIELD_STATE: IField = {};

const state: State = {
  form: {
    ...DEFAULT_FORM_STATE,
  },
  customFields: {
    ...DEFAULT_FIELD_STATE,
  },
};

const getters: GetterTree<State, any> = {
  getForm(state): IForm | null {
    return state.form;
  },
  getCustomFields(state): IField | null {
    return state.customFields;
  },
};

const mutations: MutationTree<State> = {
  setForm(state, _form: IForm) {
    state.form = { ..._form };
  },
  setCustomFields(state, _customFields: IField) {
    state.customFields = { ..._customFields };
  },
};

const actions: ActionTree<State, any> = {
  saveFormState({ commit }, payload: IForm) {
    commit("setForm", payload);
  },
  saveCustomFields({ commit }, payload: IField) {
    commit("setCustomFields", payload);
  },
  clearFormState({ commit }) {
    commit("setForm", { ...DEFAULT_FORM_STATE });
    commit("setCustomFields", { ...DEFAULT_FIELD_STATE });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
