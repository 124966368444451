export type KokattoSuccessResponse = {
  statusCode?: number;
  statusMessage?: string;
};

export function isKokattoSuccessResponse(
  response: any
): response is KokattoSuccessResponse {
  return (response as KokattoSuccessResponse).statusCode === 200;
}
