import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  setWaba: boolean;
  subsKoalaPlus: boolean;
  fillStoreInfo: boolean;
  addProduct: boolean;
  verifAcc: boolean;
  topUpCC: boolean;
  sendBC: boolean;
  setCS: boolean;
  activateCB: boolean;
};

const state: State = {
  setWaba: false,
  subsKoalaPlus: false,
  fillStoreInfo: false,
  addProduct: false,
  verifAcc: false,
  topUpCC: false,
  sendBC: false,
  setCS: false,
  activateCB: false,
};

const getters: GetterTree<State, any> = {
  getSetWaba: (state) => state.setWaba,
  getSubsKoalaPlus: (state) => state.subsKoalaPlus,
  getFillStoreInfo: (state) => state.fillStoreInfo,
  getAddProduct: (state) => state.addProduct,
  getVerifAcc: (state) => state.verifAcc,
  getTopUpCC: (state) => state.topUpCC,
  getSendBC: (state) => state.sendBC,
  getSetCS: (state) => state.setCS,
  getActivateCB: (state) => state.activateCB,
};

const mutations: MutationTree<State> = {
  updateSetWaba(state, value: boolean) {
    state.setWaba = value;
  },
  updateSubsKoalaPlus(state, value: boolean) {
    state.subsKoalaPlus = value;
  },
  updateFillStoreInfo(state, value: boolean) {
    state.fillStoreInfo = value;
  },
  updateAddProduct(state, value: boolean) {
    state.addProduct = value;
  },
  updateVerifAcc(state, value: boolean) {
    state.verifAcc = value;
  },
  updateTopUpCC(state, value: boolean) {
    state.topUpCC = value;
  },
  updateSendBC(state, value: boolean) {
    state.sendBC = value;
  },
  updateSetCS(state, value: boolean) {
    state.setCS = value;
  },
  updateActivateCB(state, value: boolean) {
    state.activateCB = value;
  },
};

const actions: ActionTree<State, any> = {
  setSetWaba(context, value: boolean) {
    context.commit("updateSetWaba", value);
  },
  setSubsKoalaPlus(context, value: boolean) {
    context.commit("updateSubsKoalaPlus", value);
  },
  setFillStoreInfo(context, value: boolean) {
    context.commit("updateFillStoreInfo", value);
  },
  setAddProduct(context, value: boolean) {
    context.commit("updateAddProduct", value);
  },
  setVerifAcc(context, value: boolean) {
    context.commit("updateVerifAcc", value);
  },
  setTopUpCC(context, value: boolean) {
    context.commit("updateTopUpCC", value);
  },
  setSendBC(context, value: boolean) {
    context.commit("updateSendBC", value);
  },
  setSetCS(context, value: boolean) {
    context.commit("updateSetCS", value);
  },
  setActivateCB(context, value: boolean) {
    context.commit("updateActivateCB", value);
  },
  clearState(context) {
    context.commit("updateSetWaba", false);
    context.commit("updateSubsKoalaPlus", false);
    context.commit("updateFillStoreInfo", false);
    context.commit("updateAddProduct", false);
    context.commit("updateVerifAcc", false);
    context.commit("updateTopUpCC", false);
    context.commit("updateSendBC", false);
    context.commit("updateSetCS", false);
    context.commit("updateActivateCB", false);
  },
};

const OnboardingState = { state, getters, mutations, actions };

export default OnboardingState;
