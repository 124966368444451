import firebase from "@firebase/app";
import "@firebase/messaging";
// import "../registerServiceWorker";

const config = {
  apiKey: "AIzaSyBVn-Wg7-_P_mfKc4zXBbjYSeA9Lhaqj90",
  authDomain: "kokatto-cs-platform.firebaseapp.com",
  databaseURL: "https://kokatto-cs-platform.firebaseio.com",
  projectId: "kokatto-cs-platform",
  storageBucket: "kokatto-cs-platform.appspot.com",
  messagingSenderId: "214016096672",
  appId: "1:214016096672:web:a778e5e95eaebac2058cc5",
  measurementId: "G-DXEN6HVHWB",
};
firebase.initializeApp(config);

const firebaseMessaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
if (firebaseMessaging) {
  firebaseMessaging.usePublicVapidKey(
    "BM8eXxywknOgVX8VFYdqLg4T37Hiu8UTT3733rR4W28rVfGZelemN_i1udUe6ae6_5e7e66ad9XKL1D90VlZuUw"
  );
}
// navigator.serviceWorker.getRegistration().then(async (registration) => {
//   if (!firebaseMessaging.vapidKey) {
//     firebaseMessaging.usePublicVapidKey(
//       "BM8eXxywknOgVX8VFYdqLg4T37Hiu8UTT3733rR4W28rVfGZelemN_i1udUe6ae6_5e7e66ad9XKL1D90VlZuUw"
//     );
//     firebaseMessaging.useServiceWorker(registration);
//     console.log("file: firebase.js:24 ~ navigator.serviceWorker.getRegistration ~ firebaseMessaging:", firebaseMessaging);
//   }
// });

export default firebaseMessaging;
