import { ActionTree, GetterTree, MutationTree } from "vuex";
import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KokattoGetTicketListResponse } from "@/serviceClients/responses/KokattoGetTicketListResponse";
import { KokattoGetTicketDetailsResponse } from "@/serviceClients/responses/KokattoGetTicketDetailsResponse";

import { KokattoTicketServiceClient } from "@/serviceClients/KokattoTicketServiceClient";
import { KokattoInboxServiceClient } from "@/serviceClients/KokattoInboxServiceClient";

import {
  State,
  TicketListStateData,
  GetTicketListActionPayload,
  GetSelectedTicketActionPayload,
} from "./types";

const DEFAULT_ASYNC_STATE = {
  loading: false,
  data: null,
  error: null,
};

const DEFAULT_STATE: State = {
  ticketList: { ...DEFAULT_ASYNC_STATE },
  selectedTicket: { ...DEFAULT_ASYNC_STATE },
};

const state: State = { ...DEFAULT_STATE };

const getters: GetterTree<State, any> = {
  getTicketListState: (state: State) => state.ticketList,
};

const mutations: MutationTree<State> = {
  beginGetTicketList(state: State) {
    state.ticketList = { ...DEFAULT_ASYNC_STATE, loading: true };
  },
  successGetTicketList(state: State, ticketListResult: TicketListStateData) {
    state.ticketList = { ...DEFAULT_ASYNC_STATE, data: ticketListResult };
  },
  errorGetTicketList(state: State, error: Error) {
    state.ticketList = { ...DEFAULT_ASYNC_STATE, error };
  },

  beginGetSelectedTicket(state: State) {
    state.selectedTicket = { ...DEFAULT_ASYNC_STATE, loading: true };
  },
  setSelectedTicket(state: State, selectedTicketResult: any) {
    state.selectedTicket = {
      ...DEFAULT_ASYNC_STATE,
      data: selectedTicketResult,
    };
  },
  errorGetSelectedTicket(state: State, error: Error) {
    state.selectedTicket = { ...DEFAULT_ASYNC_STATE, error };
  },
  removeSelectedTicket(state: State) {
    state.selectedTicket = { ...DEFAULT_ASYNC_STATE };
  },
};

const actions: ActionTree<State, any> = {
  // Retrieve ticket list from API
  async getTicketList(
    { commit, rootGetters },
    payload: GetTicketListActionPayload
  ): Promise<void> {
    try {
      const kokattoTicketServiceClient = new KokattoTicketServiceClient({
        token: rootGetters.getKokattoTokenAccess,
      });

      commit("beginGetTicketList");
      const responseData: KokattoGetTicketListResponse | KokattoErrorResponse =
        await kokattoTicketServiceClient.getTicketList(payload);
      const { tickets } = responseData as KokattoGetTicketListResponse;
      commit("successGetTicketList", tickets);
    } catch (error) {
      commit("errorGetTicketList", error);
      console.error("[ERROR] getTicketList() - TicketView module", error);
    }
  },

  async getSelectedTicket(
    { commit, rootGetters },
    payload: GetSelectedTicketActionPayload
  ): Promise<void> {
    try {
      const kokattoInboxServiceClient = new KokattoInboxServiceClient({
        token: rootGetters.getKokattoTokenAccess,
      });

      commit("beginGetSelectedTicket");
      const responseData:
        | KokattoGetTicketDetailsResponse
        | KokattoErrorResponse = await kokattoInboxServiceClient.getTicketDetails(
        payload.ticketId
      );
      const { ticket } = responseData as KokattoGetTicketDetailsResponse;
      commit("setSelectedTicket", ticket);
    } catch (error) {
      commit("errorGetTicketList", error);
      console.error("[ERROR] getTicketList() - TicketView module", error);
    }
  },

  setSelectedTicket(context, payload: any) {
    console.log("setSelectedTicket", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
