const id = {
  newChatWindowTitle: "Buat chat baru",
  newChatReceiverEmailInputPlaceholder: "Masukkan alamat email penerima",
  newChatSubjectInputPlaceholder: "Masukkan subjek chat",
  newChatSenderAccountInputLabel: "Dibalas dengan akun:",
  newChatSenderAccountInputPlaceholder: "Masukkan akun",
  newChatSendButtonLabel: "Kirim pesan",
  newChatConfirmCloseDialogTitle: "Batalkan chat baru?",
  newChatConfirmCloseDialogContent:
    "Semua info yang sudah kamu masukkan akan dihapus jika kamu batal membuat chat baru.",
  newChatConfirmCloseDialogAcceptButtonLabel: "Ya, batalkan",
  newChatConfirmCloseDialogCancelButtonLabel: "Lanjutkan chat",
  newChatInputMessageHereLabel: "Ketik isi pesan di sini",
  newChatSendSuccessMessage: "Berhasil buat chat baru untuk {0}.",
  newChatSendFailedMessage: "Gagal buat chat baru untuk {0}.",

  manualTicketReceiverCustomerContactPlaceholder:
    "Masukkan kontak pelanggan (no. HP, username, dll.)",
  otherChannelTicketCreationInfo:
    "Pesan tidak akan terkirim ke pelanggan tapi tetap masuk ke daftar chat masuk.",
};

const en = {
  newChatWindowTitle: "Create new chat",
  newChatReceiverEmailInputPlaceholder: "Enter recipient email address",
  newChatSubjectInputPlaceholder: "Enter chat subject",
  newChatSenderAccountInputLabel: "Account used to reply:",
  newChatSenderAccountInputPlaceholder: "Enter account",
  newChatSendButtonLabel: "Send message",
  newChatConfirmCloseDialogTitle: "Cancel new chat?",
  newChatConfirmCloseDialogContent:
    "All the information you have entered will be deleted if you cancel the chat.",
  newChatConfirmCloseDialogAcceptButtonLabel: "Yes, cancel",
  newChatConfirmCloseDialogCancelButtonLabel: "Continue chat",
  newChatInputMessageHereLabel: "Type message here",
  newChatSendSuccessMessage: "Successfully created a new chat to  {0}.",
  newChatSendFailedMessage: "Failed sending a new chat to  {0}.",

  manualTicketReceiverCustomerContactPlaceholder:
    "Enter the recipient contact (phone number, username, .etc)",
  otherChannelTicketCreationInfo:
    "This ticket will be created without the message being sent to the customer.",
};

export default { id, en };
