import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getSellerIdFromProcatToken } from "@/utils/jwtUtil";

type State = {
  cart: any[];
  cartCheckout: any[];
};

const state: State = {
  cart: [],
  cartCheckout: [],
};

const getters: GetterTree<State, any> = {
  isProductManualInCart: (state) => {
    const item = state.cartCheckout.find((item) => item.itemId == "");
    if (item) return true;
    else return false;
  },
  getCartPrice: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);

    let totalPrice = 0;
    const cart = state.cart;
    for (const item of cart) {
      totalPrice += item?.subTotal || 0;
    }
    return totalPrice;
  },
  getNumberOfItemInCart: (state) => (itemId: string) => {
    const cart = state.cart.filter((item) => item.itemId === itemId);
    let total = 0;
    if (cart.length > 0) {
      for (const itemCart of cart) {
        total += itemCart.numberOfItemInCart;
      }
      return total;
    } else {
      return 0;
    }
  },
  getCartItems: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);

    const cart = state.cart;
    return cart;
  },
  getCartLength: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);

    // console.log(state.cart, sellerId);
    const cart = state.cart;
    return cart.length;
  },
  isInCart: (state) => (itemId: string) => {
    let cart = [...state.cart];
    cart = cart.filter((itemCart) => {
      if (itemCart.itemId === itemId) {
        return itemCart;
      }
    });
    return cart.length > 0;
  },
  getItemById: (state) => (itemId: string) => {
    let cart = [...state.cart];
    cart = cart.filter((itemCart) => {
      if (itemCart.itemId === itemId) {
        return itemCart;
      }
    });
    return cart;
  },
  getItemByCartId: (state) => (cartId: string) => {
    let cart = [...state.cart];
    cart = cart.filter((itemCart) => {
      if (itemCart.cartId === cartId) {
        return itemCart;
      }
    });
    return cart;
  },
  getItemPriceById: (state) => (itemId: string) => {
    const cart = [...state.cart];
    let total = 0;
    for (const item of cart) {
      if (item.itemId === itemId) {
        total += item.subTotal;
      }
    }
    return total;
  },
  getWeightTotalInGram: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);

    // console.log(state.cart, sellerId);
    const cart = state.cartCheckout;

    let total = 0;
    for (const cartItem of cart) {
      if (cartItem.weightUnit.toLowerCase() == "kg") {
        total = total + cartItem.weight * 1000 * cartItem.numberOfItemInCart;
      } else {
        total += cartItem.weight * cartItem.numberOfItemInCart;
      }
    }

    return total;
  },
  getCartItemsCheckout: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);
    const cart = state.cartCheckout;
    // console.log(cart);
    return cart;
  },
  getCartCheckoutPrice: (state, getters, rootState) => (token: string) => {
    const sellerId = getSellerIdFromProcatToken(token);

    let totalPrice = 0;
    const cart = state.cartCheckout;
    for (const item of cart) {
      totalPrice += item?.subTotal || 0;
    }
    return totalPrice;
  },
};

const mutations: MutationTree<State> = {
  addCart(state, cart) {
    state.cart = [...cart];
  },
  updateItemInCart(state, itemToUpdate) {
    let cart = state.cart;
    cart = cart.map((itemCart) => {
      if (itemCart.cartId === itemToUpdate.cartId) {
        itemCart = Object.assign({}, itemToUpdate);
      }
      return itemCart;
    });

    cart = cart.filter((itemCart) => itemCart.numberOfItemInCart > 0);

    state.cart = cart;
  },
  addItemInCart(state, cartId) {
    let cart = state.cart;
    // cart = cart.filter(itemCart => itemCart.itemId !== item.itemId)
    cart = cart.map((itemCart) => {
      if (itemCart.cartId === cartId) {
        const basePrice = itemCart.subTotal / itemCart.numberOfItemInCart;
        itemCart.numberOfItemInCart += 1;
        itemCart.subTotal += basePrice;
      }
      return itemCart;
    });
    state.cart = cart;
  },
  minusItemInCart(state, cartId) {
    let cart = [...state.cart];
    // cart = cart.filter(itemCart => itemCart.itemId !== item.itemId)
    cart = cart.map((itemCart) => {
      if (itemCart.cartId === cartId) {
        const basePrice = itemCart.subTotal / itemCart.numberOfItemInCart;
        itemCart.numberOfItemInCart -= 1;
        itemCart.subTotal -= basePrice;
      }
      return itemCart;
    });

    cart = cart.filter((itemCart) => itemCart.numberOfItemInCart !== 0);

    state.cart = cart;
  },
  addItem(state, item) {
    const cart = [...state.cart];
    cart.push(item);
    state.cart = cart;
  },
  deleteItemById(state, itemId) {
    let cart = [...state.cart];
    cart = cart.filter((itemCart) => itemCart.itemId !== itemId);

    state.cart = cart;
  },
  deleteItemByCartId(state, cartId) {
    let cart = [...state.cart];
    cart = cart.filter((itemCart) => itemCart.cartId !== cartId);

    state.cart = cart;
  },
  editCartVariant(state, item) {
    let cart = [...state.cart];
    const cartId = item?.cartId;
    cart = cart.filter((itemCart) => itemCart.cartId !== cartId);

    cart.push(item);

    state.cart = cart;
  },
  addToCartNonVariant(state, item) {
    let cart = [...state.cart];

    cart = cart.filter((itemCart) => itemCart.itemId !== item.itemId);

    cart.push(item);
    state.cart = cart;
  },
  addToCartVariant(state, item) {
    const cart = [...state.cart];

    if (cart.length === 0) {
      cart.push(item);
      state.cart = cart;
      return;
    }

    let existingItemCart = [];
    const itemInCart = [];

    for (const itemCart of cart) {
      if (item.itemId === itemCart.itemId) {
        existingItemCart.push(itemCart);
      } else {
        itemInCart.push(itemCart);
      }
    }

    let isExistingItemCart = false;
    if (existingItemCart.length > 0) {
      existingItemCart = existingItemCart.map((itemCart) => {
        if (JSON.stringify(itemCart.variant) === JSON.stringify(item.variant)) {
          itemCart.numberOfItemInCart += item.numberOfItemInCart;
          itemCart.subTotal += item.subTotal;
          isExistingItemCart = true;
          return itemCart;
        } else {
          return itemCart;
        }
      });
    }

    if (!isExistingItemCart) {
      itemInCart.push(item);
    }

    state.cart = itemInCart.concat(existingItemCart);
  },
  clearCart(state) {
    state.cart = [];
  },
  addCartCheckout(state, cart) {
    state.cartCheckout = [...cart];
  },
  clearCartCheckout(state) {
    state.cartCheckout = [];
  },
  deleteItemCheckoutByCartId(state, cartId) {
    let cart = [...state.cartCheckout];
    cart = cart.filter((itemCart) => itemCart.cartId !== cartId);
    state.cartCheckout = cart;
  },
  assignCarttoCheckoutCart(state) {
    state.cartCheckout = [...state.cart];
    state.cart = [];
  },
  cloneCartfromCheckoutCart(state) {
    state.cart = [...state.cartCheckout];
  },
};

const actions: ActionTree<State, any> = {
  addCart(context, cart: any[]) {
    context.commit("addCart", cart);
  },
  editCartVariant(context, item: object) {
    context.commit("editCartVariant", item);
  },
  updateItemInCart(context, itemToUpdate: object) {
    context.commit("updateItemInCart", itemToUpdate);
  },
  addItemInCart(context, cartId: string) {
    context.commit("addItemInCart", cartId);
  },
  minusItemInCart(context, cartId: string) {
    context.commit("minusItemInCart", cartId);
  },
  addItem(context, item: object) {
    context.commit("addItem", item);
  },
  deleteItemByCartId(context, cartId: string) {
    context.commit("deleteItemByCartId", cartId);
  },
  addToCartVariant(context, item: object) {
    context.commit("addToCartVariant", item);
  },
  addToCartNonVariant(context, item: object) {
    context.commit("addToCartNonVariant", item);
  },
  deleteItemById(context, itemId: string) {
    context.commit("deleteItemById", itemId);
  },
  clearCart(context) {
    context.commit("clearCart");
  },
  assignCarttoCheckout(context) {
    context.commit("assignCarttoCheckoutCart");
  },
  clonecartFromCheckout(context) {
    context.commit("cloneCartfromCheckoutCart");
  },
  deleteItemFromCheckoutByCartId(context, cartId: string) {
    context.commit("deleteItemCheckoutByCartId", cartId);
  },
  clearCheckout(context) {
    context.commit("clearCartCheckout");
  },
};

const Cart = { state, getters, mutations, actions };

export default Cart;
