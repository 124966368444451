import { brandConstants } from "@/brand/current/PartnerConstants";

export class MediaPlatformCode {
  code: string;
  name: string;
  baseColor: string;
  filterCode: string;
  hasExpiryDate: boolean;
  allowedAttachment: Array<number>; // [transaction,image,videoimage,audio,document]
  mediaCaptionsLimit: number;
  hasReplyButton: boolean;
  multipleConnect: boolean;
  crmCodeName: string; // Constant in CRM

  static WAP = new MediaPlatformCode(
    "WAP",
    "Whatsapp",
    "#249F37",
    "whatsapp",
    true,
    [1, 0, 1, 1, 1],
    4096,
    true,
    false,
    "Whatsapp"
  );
  static IGM = new MediaPlatformCode(
    "IGM",
    "Instagram",
    "#A61D90",
    "instagram",
    true,
    [1, 0, 1, 1, 1],
    4096,
    true,
    true,
    "Instagram"
  );
  static MSG = new MediaPlatformCode(
    "MSG",
    "Facebook",
    "#3A5692",
    "messenger",
    true,
    [1, 0, 1, 1, 1],
    4096,
    true,
    true,
    "Messenger"
  );
  static TPD = new MediaPlatformCode(
    "TPD",
    "Tokopedia",
    "#329F37",
    "tokopedia",
    false,
    [0, 0, 0, 0, 0],
    4096,
    false,
    true,
    "Tokopedia"
  );
  static LZD = new MediaPlatformCode(
    "LZD",
    "Lazada",
    "#000778",
    "lazada",
    false,
    [0, 1, 0, 0, 0],
    4096,
    false,
    true,
    "Lazada"
  );
  static SHP = new MediaPlatformCode(
    "SHP",
    "Shopee",
    "#ff6600",
    "shopee",
    false,
    [0, 0, 0, 0, 0],
    4096,
    false,
    true,
    "Shopee"
  );
  static WPU = new MediaPlatformCode(
    "WPU",
    "Whatsapp",
    "#249F37",
    "whatsappUnofficial",
    false,
    [0, 0, 1, 1, 1],
    4096,
    true,
    false,
    "Whatsapp Unofficial"
  );
  static VBR = new MediaPlatformCode(
    "VBR",
    "Viber",
    "#8c51b8",
    "viber",
    false,
    [0, 0, 1, 0, 1],
    512,
    true,
    false,
    "Viber"
  );

  static LNE = new MediaPlatformCode(
    "LNE",
    "Line",
    "#293F65",
    "line",
    false,
    [1, 0, 1, 1, 0],
    512,
    false,
    false,
    "Line"
  );

  static TLG = new MediaPlatformCode(
    "TLG",
    "Telegram",
    "#03b6fc",
    "telegram",
    false,
    [1, 0, 1, 1, 1],
    512,
    false,
    false,
    "Telegram"
  );
  static LVC = new MediaPlatformCode(
    "LVC",
    "Live Chat",
    "#ff3200",
    "livechat",
    false,
    [1, 0, 1, 1, 1],
    4096,
    false,
    true,
    "Live Chat"
  );
  static EML = new MediaPlatformCode(
    "EML",
    "Email",
    "#3A5FD6",
    "email",
    false,
    [0, 0, 1, 1, 1],
    4096,
    false,
    true,
    "Email"
  );

  constructor(
    code: string,
    name: string,
    baseColor: string,
    filterCode: string,
    hasExpiryDate: boolean,
    allowedAttachment: Array<number>,
    mediaCaptionsLimit: number,
    hasReplyButton: boolean,
    multipleConnect: boolean,
    crmCodeName: string
  ) {
    this.code = code;
    this.name = name;
    this.baseColor = baseColor;
    this.filterCode = filterCode;
    this.hasExpiryDate = hasExpiryDate;
    this.allowedAttachment = allowedAttachment;
    this.mediaCaptionsLimit = mediaCaptionsLimit;
    this.hasReplyButton = hasReplyButton;
    this.multipleConnect = multipleConnect;
    this.crmCodeName = crmCodeName;
  }

  static getAllowedMediaPlatformList(): MediaPlatformCode[] {
    const allowedMedia: Array<MediaPlatformCode> = Object.values(
      MediaPlatformCode
    ).filter((media) => {
      return brandConstants.ALLOWED_MEDIA_PLATFORM.includes(media.code);
    });
    return allowedMedia.map((media) => {
      return <MediaPlatformCode>(
        MediaPlatformCode[media.code as keyof typeof MediaPlatformCode]
      );
    });
  }

  static getAllMediaPlatformList(): MediaPlatformCode[] {
    return Object.keys(MediaPlatformCode).map((code) => {
      return <MediaPlatformCode>(
        MediaPlatformCode[code as keyof typeof MediaPlatformCode]
      );
    });
  }

  static getMediaPlatformDetail(code: string): MediaPlatformCode | null {
    if (code) {
      for (const key of Object.keys(MediaPlatformCode)) {
        if (key === code) {
          return <MediaPlatformCode>(
            MediaPlatformCode[code as keyof typeof MediaPlatformCode]
          );
        }
      }
    }
    return null;
  }

  static getMediaPlatformDetailByFilterCode(
    filterCode: string
  ): MediaPlatformCode | null {
    if (filterCode) {
      for (const key of Object.keys(MediaPlatformCode)) {
        if (this.getMediaPlatformDetail(key)?.filterCode === filterCode) {
          return <MediaPlatformCode>(
            MediaPlatformCode[key as keyof typeof MediaPlatformCode]
          );
        }
      }
    }
    return null;
  }

  static isAllowedTransactionMenu(code: string): boolean {
    const allowedAttachment: number[] = this.getMediaPlatformDetail(code)
      ?.allowedAttachment || [0, 0, 0, 0, 0];
    if (allowedAttachment[0]) return true;
    return false;
  }

  static isAllowedImageMenu(code: string): boolean {
    const allowedAttachment: number[] = this.getMediaPlatformDetail(code)
      ?.allowedAttachment || [0, 0, 0, 0, 0];
    if (allowedAttachment[1]) return true;
    return false;
  }

  static isAllowedVideoImageMenu(code: string): boolean {
    const allowedAttachment: number[] = this.getMediaPlatformDetail(code)
      ?.allowedAttachment || [0, 0, 0, 0, 0];
    if (allowedAttachment[2]) return true;
    return false;
  }

  static isAllowedAudioMenu(code: string): boolean {
    const allowedAttachment: number[] = this.getMediaPlatformDetail(code)
      ?.allowedAttachment || [0, 0, 0, 0, 0];
    if (allowedAttachment[3]) return true;
    return false;
  }

  static isAllowedDocumentMenu(code: string): boolean {
    const allowedAttachment: number[] = this.getMediaPlatformDetail(code)
      ?.allowedAttachment || [0, 0, 0, 0, 0];
    if (allowedAttachment[4]) return true;
    return false;
  }

  static getMediaCaptionsLimit(code: string): number {
    return this.getMediaPlatformDetail(code)?.mediaCaptionsLimit || 4096;
  }

  static getCustomerServiceFilterCode(code: string): string {
    return this.getMediaPlatformDetail(code)?.filterCode || "";
  }
}
