import { KokattoLoginRequest } from "./requests/KokattoLoginRequest";
import axios from "axios";
import { KokattoLoginResponse } from "./responses/KokattoLoginResponse";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoRefreshTokenRequest } from "./requests/KokattoRefreshTokenRequest";
import { KokattoUpdateCISFCMRequest } from "./requests/KokattoUpdateCISFCMRequest";
export class KokattoAuthServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
          pushNotifUrl?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_CIS ?? "";
    }

    if (!this.pushNotifUrl) {
      this.pushNotifUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL ?? "";
    }
  }

  async login(
    request: KokattoLoginRequest
  ): Promise<KokattoLoginResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csppushnotif/auth/login`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<KokattoLoginResponse>(url, request);
      return response.data;
    });
  }

  async refreshToken(request: KokattoRefreshTokenRequest) {
    const url = `${this.baseUrl}/csppushnotif/auth/refreshAccessToken`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<KokattoLoginResponse>(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateFcmToken(request: KokattoUpdateCISFCMRequest) {
    const url = `${this.cisUrl}/users/userProfile`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put<any>(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateOnlineStatusCSP(isOnline: boolean) {
    const url = `${this.pushNotifUrl}/pushnotif/status`;
    let status = "OFFLINE";
    if (isOnline) {
      status = "ONLINE";
    }

    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<any>(
        url,
        { status: status },
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data;
    });
  }

  async updateStatusCSP() {
    const url = `${this.pushNotifUrl}/pushnotif/offline`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.post<any>(url, null, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }
}
