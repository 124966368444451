const id = {
  settingGroupManagementMemberTitle: "Daftar Anggota Group",
  settingGroupManagementTitle: "Daftar Group",
  settingGroupManagementDescription: "",
  settingGroupManagementDefaultGroupLabel: "(default group)",
  settingGroupManagementTableHeaderName: "Nama",
  settingGroupManagementTableHeaderDesc: "Deskripsi",
  settingGroupManagementTableHeaderMembers: "Members",
  settingGroupManagementTableHeaderAction: "Aksi",
  settingGroupManagementAddButton: "Tambah Group",
  settingGroupManagementEditButton: "Edit Group",
  settingGroupManagementFormNameLabel: "Nama",
  settingGroupManagementFormNamePlaceHolder: "Nama",
  settingGroupManagementFormDescLabel: "Deskripsi (opsional)",
  settingGroupManagementFormDescPlaceHolder: "Deskripsi",
  settingGroupManagementFormAgentListLabel: "Pilih daftar pengguna",
  settingGroupManagementFormAgentListPlaceHolder: "Pilih dari daftar agen",
  settingGroupManagementFormOperationalHourLabel: "Jam Operasional",
  settingGroupManagementFormOperationalHourPlaceHolder: "Set jam operasional",
  settingGroupManagementFormDefaultGroupLabel: "Tandai sebagai group default",
  settingGroupManagementFormDefaultGroupPlaceHolder:
    "Obrolan baru akan diberikan secara acak ke anggota grup default. Saat sistem penetapan otomatis berjalan, hanya 1 grup yang dapat dijadikan default",
  settingGroupManagementDialogCreateButton: "Tambahkan",
  settingGroupManagementDialogUpdateButton: "Simpan",

  settingGroupManagementDeleteTitle: "Hapus {0} dari group?",
  settingGroupManagementDeleteContent:
    "Pastikan tidak ada anggota di grup ini sebelum menghapusnya.",
  settingGroupManagementDeleteConfirm: "Ya, Hapus",
  settingGroupManagementDeleteCancel: "Tidak",

  settingGroupManagementFormNameValidationErrorEmpty: "Nama wajib diisi",
  settingGroupManagementFormNameValidationErrorLength: "Maksimal 15 karakter",
  settingGroupManagementSuccessCreateUpdateMessage: "Berhasil menyimpan group",
  settingGroupManagementFailedCreateUpdateMessage: "Gagal menyimpan group",
  settingGroupManagementSuccessDeleteMessage: "Group berhasil dihapus",
  settingGroupManagementFailedDeleteMessage: "Gagal menghapus group",
  settingGroupManagementSuccessDeleteMember: "Berhasil menghapus member group",
  settingGroupManagementFailedDeleteMember: "Gagal menghapus member group",
  settingGroupManagementSuccessAddMember: "Sukses menambahkan member group",
  settingGroupManagementFailedAddMember: "Gagal menambahkan member group",
  settingGroupManagementFailedGetList: "Gagal memuat group",
};

const en = {
  settingGroupManagementSelectTitle: "Select Group",
  settingGroupManagementMemberTitle: "Group Member List",
  settingGroupManagementTitle: "Group List",
  settingGroupManagementDescription: "",
  settingGroupManagementDefaultGroupLabel: "(default group)",
  settingGroupManagementTableHeaderName: "Name",
  settingGroupManagementTableHeaderDesc: "Description",
  settingGroupManagementTableHeaderMembers: "Members",
  settingGroupManagementTableHeaderAction: "Action",
  settingGroupManagementAddButton: "Add Group",
  settingGroupManagementEditButton: "Edit Group",
  settingGroupManagementFormNameLabel: "Name",
  settingGroupManagementFormNamePlaceHolder: "Name",
  settingGroupManagementFormDescLabel: "Description (optional)",
  settingGroupManagementFormDescPlaceHolder: "Description",
  settingGroupManagementFormAgentListLabel: "Choose agent",
  settingGroupManagementFormAgentListPlaceHolder: "Choose agent",
  settingGroupManagementFormOperationalHourLabel: "Operational Hour",
  settingGroupManagementFormOperationalHourPlaceHolder: "Operational Hour",
  settingGroupManagementFormDefaultGroupLabel: "Mark as default group",
  settingGroupManagementFormDefaultGroupPlaceHolder:
    "New chats will be randomly assigned to members of the default group. When the auto assign system is running, only 1 group can be made the default",
  settingGroupManagementDialogCreateButton: "Add",
  settingGroupManagementDialogUpdateButton: "Save",

  settingGroupManagementDeleteTitle: "Remove {0} from group?",
  settingGroupManagementDeleteContent:
    "Make sure there is no member in this group before removing it.",
  settingGroupManagementDeleteConfirm: "Yes, Remove",
  settingGroupManagementDeleteCancel: "No",

  settingGroupManagementFormNameValidationErrorEmpty: "Name is Required",
  settingGroupManagementFormNameValidationErrorLength: "Max 15 Characters",
  settingGroupManagementSuccessCreateUpdateMessage: "Group Save Successfully",
  settingGroupManagementFailedCreateUpdateMessage: "Failed Saving Group, {0}",
  settingGroupManagementSuccessDeleteMessage: "Group Deleted",
  settingGroupManagementFailedDeleteMessage: "Failed Deleting Group",
  settingGroupManagementSuccessDeleteMember: "Success Deleting Group Member",
  settingGroupManagementFailedDeleteMember: "Failed Deleting Group Member",
  settingGroupManagementSuccessAddMember: "Success Adding Group Member",
  settingGroupManagementFailedAddMember: "Failed Adding Group Member",
  settingGroupManagementFailedGetList: "Failed Load Group",
};

export default { id, en };
