import axios from "axios";
import { KoalaServiceClient } from "@/serviceClients/KoalaServiceClient";
import { KoalaTrackerRequest } from "@/serviceClients/requests/KoalaTrackerRequest";
import { KoalaTrackerResponse } from "@/serviceClients/responses/KoalaTrackerResponse";

export class KoalaTrackerServiceClient extends KoalaServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOALA_OLTP ?? "";
    }
  }

  async postTracker(
    request: KoalaTrackerRequest
  ): Promise<KoalaTrackerResponse> {
    const url = `${this.baseUrl}/tracker/page-visitation`;
    return this.failsafeHttpRequest(async () => {
      let headerConfig = {};
      if (this.token) {
        headerConfig = {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        };
      }
      const response = await axios.post(url, request, headerConfig);
      return response.data;
    });
  }
}
