import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  mainOverlay: boolean;
  zIndex: number;
};

const state: State = {
  mainOverlay: false,
  zIndex: 1,
};

const getters: GetterTree<State, any> = {
  getMainOverlay: (state) => state.mainOverlay,
  getZIndex: (state) => state.zIndex,
};

const mutations: MutationTree<State> = {
  updateMainOverlay(state, isShow: boolean) {
    state.mainOverlay = isShow;
  },
  updateZIndex(state, num: number) {
    state.zIndex = num;
  },
  clearOverlay(state) {
    state.mainOverlay = false;
    state.zIndex = 1;
  },
};

const actions: ActionTree<State, any> = {
  setMainOverlay(context, isShow: boolean) {
    context.commit("updateMainOverlay", isShow);
  },
  clearMainOverlay(context) {
    context.commit("clearOverlay");
  },
  setZIndex(context, num: number) {
    context.commit("updateZIndex", num);
  },
};

const MainOverlay = { state, getters, mutations, actions };

export default MainOverlay;
