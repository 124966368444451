const id = {
  macrosSearchQuickResponse: "Cari Quick Response",
  macrosPreview: "Lihat preview",
  macrosListNotFound: "Hasil pencarian tidak ditemukan",

  macrosPreviewLoading: "Memuat Preview Macro",
  macrosPreviewSetTicketStatus: "Set Status Tiket",
  macrosPreviewSetTicketSubject: "Set Subjek Tiket",
  macrosPreviewSendAsPublicReply: "Kirim sebagai public reply",
  macrosPreviewSendAsInternalNote: "Kirim sebagai internal note",
  macrosPreviewCancel: "Batalkan",
  macrosPreviewConfirm: "Terapkan Macro",
};

const en = {
  macrosSearchQuickResponse: "Search Quick Response",
  macrosPreview: "See preview",
  macrosListNotFound: "Search result not found",

  macrosPreviewLoading: "Loading Macro Preview",
  macrosPreviewSetTicketStatus: "Set Ticket Status",
  macrosPreviewSetTicketSubject: "Set Ticket Subject",
  macrosPreviewSendAsPublicReply: "Send as public reply",
  macrosPreviewSendAsInternalNote: "Send as internal note",
  macrosPreviewCancel: "Cancel",
  macrosPreviewConfirm: "Apply Macro",
};

export default { id, en };
