import moment from "moment";
import { HeaderColumn } from "./types";

/**
 * Default filter selection settings for customer info and remarks/subject.
 *
 * @type {Object}
 * @property {string} customerInfoFilterSelection - Default filter selection for customer information.
 * @property {string} remarksOrSubjectSelection - Default filter selection for remarks or subject.
 */
export const DEFAULT_FILTER_SELECTION = {
  customerInfoFilterSelection: "ticketId",
  remarksOrSubjectSelection: "remarks",
};

/**
 * Default filter field values, including date ranges, customer service info, and ticket status.
 *
 * @type {Object}
 * @property {string} customerInfoField - Default field value for customer information.
 * @property {string[]} tagField - Default field value for tags.
 * @property {string[]} filterCreatedTimeDateRange - Default filter value for created time date range.
 * @property {string[]} filterClosedTimeDateRange - Default filter value for closed time date range.
 * @property {string} remarksOrSubjectField - Default field value for remarks or subject.
 * @property {Object} filterSelection - Default filter selection settings.
 * @property {string} mediaPlatform - Default field value for media platform.
 * @property {string} csId - Default field value for customer service ID.
 * @property {string} closedBy - Default field value for the user who closed the ticket.
 * @property {string} createdBy - Default field value for the user who created the ticket.
 * @property {string} csGroup - Default field value for customer service group.
 * @property {string} status - Default field value for ticket status.
 * @property {string} priority - Default field value for ticket priority.
 * @property {string} fields - Default field value for additional fields.
 * @property {string} keyword - Default field value for keyword search.
 */
export const DEFAULT_FILTER_FIELD = {
  customerInfoField: "",
  tagField: [""],
  filterCreatedTimeDateRange: [
    moment().format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ],
  filterClosedTimeDateRange: [],
  remarksOrSubjectField: "",
  filterSelection: DEFAULT_FILTER_SELECTION,
  mediaPlatform: "",
  csId: "",
  closedBy: "",
  createdBy: "",
  csGroup: "",
  status: "closed",
  priority: "",
  fields: "",
  keyword: "",
};

/**
 * Default state for tag filter UI settings.
 *
 * @type {Object}
 * @property {boolean} openFilter - Indicates whether the filter UI is open.
 * @property {boolean} loading - Indicates whether the filter UI is loading.
 * @property {boolean} showSelectButton - Indicates whether the select button is shown.
 * @property {boolean} selectAll - Indicates whether all items are selected.
 */
export const DEFAULT_TAG_FILTER_STATE = {
  openFilter: false,
  loading: false,
  showSelectButton: false,
  selectAll: false,
};

/**
 * Definitions for the headers in a ticket chat list.
 *
 * @type {HeaderColumn[]}
 * @property {any} id - The ID of the header column (default is null).
 * @property {string} key - The key for the header column.
 * @property {string} name - The name/label of the header column.
 * @property {number} selectedIndex - The selected index for the header column (default is -1).
 */
export const HEADER_COLUMN_SELECTIONS: HeaderColumn[] = [
  {
    id: null,
    key: "id",
    name: "$vuetify.ticketChatListHeaderTicketId",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "groupId",
    name: "$vuetify.ticketChatListHeaderCSGroup",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "createdAt",
    name: "$vuetify.ticketChatListHeaderCreatedDate",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "customer",
    name: "$vuetify.ticketChatListHeaderCustomerContact",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "assigneeId",
    name: "$vuetify.ticketChatListHeaderAssignee",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "closedAt",
    name: "$vuetify.ticketChatListHeaderClosedDate",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "remarks",
    name: "$vuetify.ticketChatListHeaderRemarks",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "subject",
    name: "$vuetify.ticketChatListHeaderSubject",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "mediaPlatform",
    name: "$vuetify.ticketChatListHeaderChannel",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "closedBy",
    name: "$vuetify.ticketChatListHeaderClosedBy",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "priority",
    name: "$vuetify.ticketChatListHeaderPriority",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "tags",
    name: "$vuetify.ticketChatListHeaderTag",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "submitterId",
    name: "$vuetify.ticketChatListHeaderCreatedBy",
    selectedIndex: -1,
  },
  {
    id: null,
    key: "status",
    name: "$vuetify.ticketChatListHeaderStatus",
    selectedIndex: -1,
  },
];

/**
 * Default columns to be displayed in the ticket chat list, using predefined header column selections.
 *
 * @type {HeaderColumn[]}
 */
export const DEFAULT_HEADER_COLUMNS: HeaderColumn[] = [
  HEADER_COLUMN_SELECTIONS[0], // "id"
  HEADER_COLUMN_SELECTIONS[1], // "groupId"
  HEADER_COLUMN_SELECTIONS[2], // "createdAt"
  HEADER_COLUMN_SELECTIONS[3], // "customer"
  HEADER_COLUMN_SELECTIONS[4], // "assigneeId"
  HEADER_COLUMN_SELECTIONS[5], // "closedAt"
  HEADER_COLUMN_SELECTIONS[6], // "remarks"
  HEADER_COLUMN_SELECTIONS[8], // "mediaPlatform"
  HEADER_COLUMN_SELECTIONS[9], // "closedBy"
  HEADER_COLUMN_SELECTIONS[11], // "tags"
  HEADER_COLUMN_SELECTIONS[13], // "status"
];
