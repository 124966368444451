export default {
  en: {
    addLinkTitle: "Add link",
    addLinkUrl: "Enter URL",
    addLinkUrlHint: "Enter the URL of the link you want to add",
    addLinkButton: "Add",
    uploadImageError: "Failed to upload image, please try again.",
  },
  id: {
    addLinkTitle: "Tambahkan tautan",
    addLinkUrl: "Masukkan URL",
    addLinkUrlHint: "Masukkan URL tautan yang ingin Anda tambahkan",
    addLinkButton: "Tambahkan",
    uploadImageError: "Gagal dalam mengupload gambar, silahkan coba lagi.",
  },
};
