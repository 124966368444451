import { ActionTree, GetterTree, MutationTree } from "vuex";

type BankAccountModel = {
  _id: string;
  accountID: string;
  bankType: string;
  bank: string;
  accountNo: string;
  accountHolder: string;
  asset: string;
  createdAt: number;
  updatedAt: number;
  isPrimary: boolean;
  inquiryStatus: string;
};

type State = {
  primaryBankAccount: BankAccountModel;
  newPrimaryBankAccount: BankAccountModel;
  isPrimaryBankAccountAlertSuccess: boolean;
  isPrimaryBankAccountAlertClosed: boolean;
  isShowBonusCreditDashboard: boolean;
  isShowBonusCreditChatCredit: boolean;
};

const state: State = {
  primaryBankAccount: {
    _id: "",
    accountID: "",
    bankType: "",
    bank: "",
    accountNo: "",
    accountHolder: "",
    asset: "",
    createdAt: 0,
    updatedAt: 0,
    isPrimary: false,
    inquiryStatus: "",
  },
  newPrimaryBankAccount: {
    _id: "",
    accountID: "",
    bankType: "",
    bank: "",
    accountNo: "",
    accountHolder: "",
    asset: "",
    createdAt: 0,
    updatedAt: 0,
    isPrimary: false,
    inquiryStatus: "",
  },
  isPrimaryBankAccountAlertSuccess: false,
  isPrimaryBankAccountAlertClosed: false,
  isShowBonusCreditDashboard: true,
  isShowBonusCreditChatCredit: true,
};

const getters: GetterTree<State, any> = {
  getPrimaryBankAccount: (state) => state.primaryBankAccount,
  getNewPrimaryBankAccount: (state) => state.newPrimaryBankAccount,
  isPrimaryBankAccountAlertSuccess: (state) =>
    state.isPrimaryBankAccountAlertSuccess,
  isPrimaryBankAccountAlertClosed: (state) =>
    state.isPrimaryBankAccountAlertClosed,
  isShowBonusCreditDashboard: (state) => state.isShowBonusCreditDashboard,
  isShowBonusCreditChatCredit: (state) => state.isShowBonusCreditChatCredit,
};

const mutations: MutationTree<State> = {
  updatePrimaryBankAccount(state, account: BankAccountModel) {
    state.primaryBankAccount = account;
  },
  updateNewPrimaryBankAccount(state, account: BankAccountModel) {
    state.newPrimaryBankAccount = account;
  },
  updateIsPrimaryBankAccountAlertSuccess(
    state,
    isPrimaryBankAccountAlertSuccess: boolean
  ) {
    state.isPrimaryBankAccountAlertSuccess = isPrimaryBankAccountAlertSuccess;
  },
  updateIsPrimaryBankAccountAlertClosed(
    state,
    isPrimaryBankAccountAlertClosed: boolean
  ) {
    state.isPrimaryBankAccountAlertClosed = isPrimaryBankAccountAlertClosed;
  },
  updateIsShowBonusCreditDashboard(state, isShowBonusCreditDashboard: boolean) {
    state.isShowBonusCreditDashboard = isShowBonusCreditDashboard;
  },
  updateIsShowBonusCreditChatCredit(
    state,
    isShowBonusCreditChatCredit: boolean
  ) {
    state.isShowBonusCreditChatCredit = isShowBonusCreditChatCredit;
  },
};

const actions: ActionTree<State, any> = {
  setPrimaryBankAccount(context, account: BankAccountModel) {
    context.commit("updatePrimaryBankAccount", account);
  },
  clearPrimaryBankAccout(context) {
    context.commit("updatePrimaryBankAccount", {
      _id: "",
      accountID: "",
      bankType: "",
      bank: "",
      accountNo: "",
      accountHolder: "",
      asset: "",
      createdAt: 0,
      updatedAt: 0,
      isPrimary: false,
      inquiryStatus: "",
    });
  },
  setNewPrimaryBankAccount(context, account: BankAccountModel) {
    context.commit("updateNewPrimaryBankAccount", account);
  },
  clearNewPrimaryBankAccout(context) {
    context.commit("updateNewPrimaryBankAccount", {
      _id: "",
      accountID: "",
      bankType: "",
      bank: "",
      accountNo: "",
      accountHolder: "",
      asset: "",
      createdAt: 0,
      updatedAt: 0,
      isPrimary: false,
      inquiryStatus: "",
    });
  },
  setIsPrimaryBankAccountAlertSuccess(
    context,
    isPrimaryBankAccountAlertSuccess: boolean
  ) {
    context.commit(
      "updateIsPrimaryBankAccountAlertSuccess",
      isPrimaryBankAccountAlertSuccess
    );
  },
  clearIsPrimaryBankAccountAlertSuccess(context) {
    context.commit("updateIsPrimaryBankAccountAlertSuccess", false);
  },
  setIsPrimaryBankAccountAlertClosed(
    context,
    isPrimaryBankAccountAlertClosed: boolean
  ) {
    context.commit(
      "updateIsPrimaryBankAccountAlertClosed",
      isPrimaryBankAccountAlertClosed
    );
  },
  clearIsPrimaryBankAccountAlertClosed(context) {
    context.commit("updateIsPrimaryBankAccountAlertClosed", false);
  },
  setIsShowBonusCreditDashboard(context, isShowBonusCreditDashboard: boolean) {
    context.commit(
      "updateIsShowBonusCreditDashboard",
      isShowBonusCreditDashboard
    );
  },
  setIsShowBonusCreditChatCredit(
    context,
    isShowBonusCreditChatCredit: boolean
  ) {
    context.commit(
      "updateIsShowBonusCreditChatCredit",
      isShowBonusCreditChatCredit
    );
  },
};

const Wallet = { state, getters, mutations, actions };

export default Wallet;
