import { ActionTree, GetterTree, MutationTree } from "vuex";
import { KokattoCampaignModel } from "@/serviceClients/responses/KokattoCampaignResponse";

type State = {
  data: KokattoCampaignModel | null;
};

const state: State = {
  data: null,
};

const getters: GetterTree<State, any> = {
  getTemplate: (state) => state.data,
};

const mutations: MutationTree<State> = {
  updateTemplate(state, value: KokattoCampaignModel) {
    state.data = value;
  },
};

const actions: ActionTree<State, any> = {
  setTemplate(context, value: KokattoCampaignModel) {
    context.commit("updateTemplate", value);
  },
  clearTemplate(context) {
    context.commit("updateTemplate", {
      data: null,
    });
  },
};

const Template = { state, getters, mutations, actions };

export default Template;
