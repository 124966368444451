const id = {
  ticketChatListSubtitle: "Kamu bisa melihat info detail semua chat yang masuk",
  ticketChatListFilterTab: "Cari data chat",
  ticketChatListCustomDataTableTab: "Custom data tabel",
  ticketChatListSearchGroupCs: "CS Group",
  ticketChatListSearchCreatedBy: "Dibuat oleh",
  ticketChatListSearchCreatedByCustomer: "Customer",
  ticketChatListSearchStatus: "Status",
  ticketChatListSelectionAll: "Semua",
  ticketChatListSearchCreatedTime: "Waktu dibuat",
  ticketChatListSearchClosedTime: "Waktu selesai",
  ticketChatListSearchFieldInput: "Cari field input",
  ticketChatListSearchKeyword: "Cari kata kunci pesan",

  ticketChatListNotFound: "Data chat tidak ditemukan",
  ticketChatListNotFoundSubtitle:
    "Tidak ada chat yang sesuai dengan kriteria pencarian kamu",
  ticketChatListHeaderAction: "Aksi",

  ticketChatListHeaderTicketId: "Ticket ID",
  ticketChatListHeaderCSGroup: "CS Group",
  ticketChatListHeaderCreatedDate: "Waktu tiket dibuat",
  ticketChatListHeaderFieldInput: "Field input",
  ticketChatListHeaderCustomerContact: "Nama, No. HP & Email",
  ticketChatListHeaderAssignee: "Tiket ditugaskan oleh",
  ticketChatListHeaderClosedDate: "Waktu tiket selesai",
  ticketChatListHeaderRemarks: "Keterangan",
  ticketChatListHeaderSubject: "Subjek",
  ticketChatListHeaderChannel: "Channel",
  ticketChatListHeaderClosedBy: "Tiket diselesaikan oleh",
  ticketChatListHeaderPriority: "Prioritas",
  ticketChatListHeaderTag: "Tag",
  ticketChatListHeaderCreatedBy: "Tiket dibuat oleh",
  ticketChatListHeaderStatus: "Status tiket",

  ticketChatListDeleteAllCustomFieldButton: "Hapus semua pilihan",
  ticketChatListDeleteAllCustomizedDataTitle: "Customized Data",

  ticketChatListDownloadLabel: "Data semua chat",
  ticketChatListDownloadButton: "Download data chat",
  ticketChatListDownloadSuccess: "Berhasil download data chat.",
  ticketChatListDownloadFailed: "Gagal download data chat. Silakan coba lagi.",

  ticketChatListMaximumSelectionWarning:
    "Melebihi batas maks. {0} data untuk ditampilkan.",

  menuTicketList: "Daftar Chat",

  ticketChatListClearDateButton: "Hapus",
};

const en = {
  ticketChatListSubtitle:
    "You can view detail information about all incoming chats.",
  ticketChatListFilterTab: "Search chat data",
  ticketChatListCustomDataTableTab: "Customize table data",
  ticketChatListSearchGroupCs: "CS Group",
  ticketChatListSearchCreatedBy: "Created By",
  ticketChatListSearchCreatedByCustomer: "Customer",
  ticketChatListSearchStatus: "Status",
  ticketChatListSelectionAll: "All",
  ticketChatListSearchCreatedTime: "Created time",
  ticketChatListSearchClosedTime: "Closed time",
  ticketChatListSearchFieldInput: "Search field input",
  ticketChatListSearchKeyword: "Search message keyword",

  ticketChatListNotFound: "No chat data found",
  ticketChatListNotFoundSubtitle: "Nothing matched your search criteria",
  ticketChatListHeaderAction: "Action",
  ticketChatListHeaderTicketId: "Ticket ID",
  ticketChatListHeaderCSGroup: "CS Group",
  ticketChatListHeaderCreatedDate: "Ticket created date",
  ticketChatListHeaderFieldInput: "Field input",
  ticketChatListHeaderCustomerContact: "Name, No. HP & Email",
  ticketChatListHeaderAssignee: "Ticket assignee",
  ticketChatListHeaderClosedDate: "Ticket closed date",
  ticketChatListHeaderRemarks: "Remarks",
  ticketChatListHeaderSubject: "Subject",
  ticketChatListHeaderChannel: "Channel",
  ticketChatListHeaderClosedBy: "Ticket closed by",
  ticketChatListHeaderPriority: "Priority",
  ticketChatListHeaderTag: "Tag",
  ticketChatListHeaderCreatedBy: "Ticket created by",
  ticketChatListHeaderStatus: "Ticket status",

  ticketChatListDeleteAllCustomFieldButton: "Remove all selections",
  ticketChatListDeleteAllCustomizedDataTitle: "Customized Data",

  ticketChatListDownloadLabel: "All data chat",
  ticketChatListDownloadButton: "Download data chat",
  ticketChatListDownloadSuccess: "Successfully downloaded chat data.",
  ticketChatListDownloadFailed:
    "Failed to download chat data. Please try again.",

  ticketChatListMaximumSelectionWarning:
    "Exceed max. limit of {0} data to be displayed.",

  menuTicketList: "Chat List",

  ticketChatListClearDateButton: "Clear",
};
export default { id, en };
