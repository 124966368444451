import { ActionTree, GetterTree, MutationTree } from "vuex";

type ContactModel = {
  _id: string;
  clientId: string;
  channel: string;
  contact: string;
  accountId?: string;
  contactId?: string;
  channelId?: string;
  name: string;
  tags?: string[];
  imgProfileUrl?: string;
  countryCode?: string;
  country?: string;
  additionalData?: string;
};

type State = {
  receiver: Array<ContactModel>;
};

const state: State = {
  receiver: [],
};

const getters: GetterTree<State, any> = {
  getReceiver: (state) => state.receiver,
};

const mutations: MutationTree<State> = {
  updateReceiver(state, contacts: Array<ContactModel>) {
    state.receiver = contacts;
  },
};

const actions: ActionTree<State, any> = {
  setReceiver(context, contacts: Array<ContactModel>) {
    context.commit("updateReceiver", contacts);
  },
  clearReceiver(context) {
    context.commit("updateReceiver", {
      receiver: [],
    });
  },
};

const Broadcast = { state, getters, mutations, actions };

export default Broadcast;
