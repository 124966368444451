import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  item: object | null;
  note: string;
  itemChooseId: string;
  chooseVariant: any[];
};

const state: State = {
  item: null,
  note: "",
  itemChooseId: "",
  chooseVariant: [],
};

const getters: GetterTree<State, any> = {
  getItem: (state) => state.item,
  getNote: (state) => state.note,
  getItemChooseId: (state) => state.itemChooseId,
  getChooseVariant: (state) => state.chooseVariant,
  getVariantPrice: (state) => {
    let total = 0;

    for (const variant of state.chooseVariant) {
      if (!(variant.maxSelect === 1 && variant.minSelect === 1)) {
        for (const variantOption of variant.variantChoosed) {
          if (variantOption.checked) {
            total += variantOption.valuePrice;
          }
        }
      } else {
        total += variant.variantChoosed.valuePrice;
      }
    }
    return total;
  },
};

const mutations: MutationTree<State> = {
  changeItem(state, item: object) {
    state.item = item;
  },
  changeNote(state, note) {
    state.note = note;
  },
  changeItemChooseId(state, newItemId) {
    state.itemChooseId = newItemId;
  },
  changeVariantRequired(state, variant) {
    const variantIndex = state.chooseVariant.findIndex((item) => {
      return variant.optionId === item.optionId;
    });

    if (variantIndex !== -1) {
      const existingVariant = state.chooseVariant[variantIndex];
      state.chooseVariant.splice(variantIndex, 1);
      if (
        existingVariant.variantChoosed.valueId !==
        variant.variantChoosed.valueId
      ) {
        state.chooseVariant.push(variant);
      }
    } else {
      state.chooseVariant.push(variant);
    }
  },
  changeVariantOptional(state, variant) {
    const variantIndex = state.chooseVariant.findIndex((item) => {
      return variant.optionId === item.optionId;
    });

    if (variantIndex !== -1) {
      state.chooseVariant.splice(variantIndex, 1);
      state.chooseVariant.push(variant);
    } else {
      state.chooseVariant.push(variant);
    }
  },
  changeVariantManual(state, variant) {
    const variantIndex = state.chooseVariant.findIndex((item) => {
      return variant.optionId == item.optionId;
    });

    if (variantIndex !== -1) {
      state.chooseVariant.splice(variantIndex, 1);
      state.chooseVariant.push(variant);
    } else {
      state.chooseVariant.push(variant);
    }
  },
  clearVariantManual(state) {
    const variantIndex = state.chooseVariant.findIndex((item) => {
      return item.optionId == "";
    });

    if (variantIndex !== -1) {
      state.chooseVariant.splice(variantIndex, 1);
    }
  },
  clearChooseVariant(state) {
    state.chooseVariant = [];
  },
};

const actions: ActionTree<State, any> = {
  changeItem(context, item: object) {
    context.commit("changeItem", item);
  },
  clearItem(context) {
    context.commit("changeItem", null);
  },
  changeNote(context, note) {
    context.commit("changeNote", note);
  },
  clearNote(context) {
    context.commit("changeNote", "");
  },
  changeVariantRequired(context, variant) {
    context.commit("changeVariantRequired", variant);
  },
  changeVariantOptional(context, variant) {
    context.commit("changeVariantOptional", variant);
  },
  changeVariantManual(context, variant) {
    context.commit("changeVariantManual", variant);
  },
  clearVariantManual(context) {
    context.commit("clearVariantManual");
  },
  clearChooseVariant(context) {
    context.commit("clearChooseVariant");
  },
  changeItemChooseId(context, newItemId) {
    context.commit("changeItemChooseId", newItemId);
  },
  clearItemChooseId(context) {
    context.commit("changeItemChooseId", "");
  },
};

const Variant = { state, getters, mutations, actions };

export default Variant;
