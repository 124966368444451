import { AxiosError } from "axios";
import { KoalaErrorResponse } from "@/serviceClients/responses/KoalaErrorResponse";

export abstract class KoalaServiceClient {
  protected baseUrl: string;
  protected token: string;

  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    if (typeof args === "string") {
      this.baseUrl = args;
      this.token = process.env.VUE_APP_KOKATTO_TOKEN ?? "";
    } else {
      this.baseUrl = args?.baseUrl ?? "";
      this.token = args?.token ?? process.env.VUE_APP_KOKATTO_TOKEN ?? "";
    }
  }

  protected async failsafeHttpRequest<T>(
    requestFunction: () => Promise<T | KoalaErrorResponse>
  ): Promise<T | KoalaErrorResponse> {
    try {
      return await requestFunction();
    } catch (error: any) {
      const axiosError = error as AxiosError<KoalaErrorResponse>;
      return (
        axiosError.response?.data ?? {
          statusCode: error.response?.data?.statusCode || error.code,
          statusMessage: error.response?.data?.statusMessage || error.message,
          message: error.response?.data?.message || error.request.statusText,
        }
      );
    }
  }

  protected async failsafeHttpRequestThrowable<T>(
    requestFunction: () => Promise<T>
  ): Promise<T> {
    try {
      return await requestFunction();
    } catch (error: any) {
      const axiosError = error as AxiosError<KoalaErrorResponse>;
      throw axiosError;
      // return (
      //   axiosError.response?.data ?? {
      //     statusCode: error.response.data.statusCode,
      //     statusMessage: error.response.data.statusMessage,
      //     message: error.response.data.message,
      //   }
      // );
    }
  }
}
