import firebaseMessaging from "@/plugins/firebase";
/* eslint-disable no-console */

import { register } from "register-service-worker";

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },
  registered(registration) {
    console.log("Service worker has been registered.");
    console.log(
      "registered ~ JSON.parse(JSON.stringify(firebaseMessaging)):",
      JSON.parse(JSON.stringify(firebaseMessaging))
    );
    // if (!firebaseMessaging) return;
    // firebaseMessaging.usePublicVapidKey(
    //   "BM8eXxywknOgVX8VFYdqLg4T37Hiu8UTT3733rR4W28rVfGZelemN_i1udUe6ae6_5e7e66ad9XKL1D90VlZuUw"
    // );'
    firebaseMessaging &&
      firebaseMessaging.useServiceWorker(
        registration as ServiceWorkerRegistration
      );
    console.log(
      "file: registerServiceWorker.ts:22 ~ registered ~ firebaseMessaging:",
      firebaseMessaging
    );
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    // alert("updated");
    deleteCookies();
    deleteLocalStorage();
    console.log("New content is available; please refresh.");
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },
});
// }

function deleteCookies() {
  const Cookies = document.cookie.split(";");
  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
}

function deleteLocalStorage() {
  window.localStorage.clear();
}
