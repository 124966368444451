import axios from "axios";
import { KokattoServiceClient } from "./KokattoServiceClient";

export class KoalaPlusUserConsentServiceClient extends KokattoServiceClient {
  async getUserConsent(consentURL: string): Promise<string> {
    const response = await axios.get(consentURL, {
      responseType: "text",
    });
    return response.data;
  }
}
