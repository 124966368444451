import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import { KoalaErrorResponse } from "@/serviceClients/responses/KoalaErrorResponse";
import { ProcatErrorResponse } from "@/serviceClients/responses/ProcatErrorResponse";

export abstract class RefreshableRequestHelper {
  public static async requestKokatto<T>(
    refreshableRequest: () => Promise<T> | Promise<KokattoErrorResponse>
  ): Promise<T | KokattoErrorResponse> {
    const response = await refreshableRequest();
    return response;
  }

  public static async requestKoalaPlus<T>(
    refreshableRequest: () => Promise<T> | Promise<KoalaErrorResponse>
  ): Promise<T | KoalaErrorResponse> {
    const response = await refreshableRequest();
    return response;
  }

  public static async requestKoala<T>(
    refreshableRequest: () => Promise<T> | Promise<any>
  ): Promise<T | KoalaErrorResponse> {
    const response = await refreshableRequest();
    return response;
  }

  public static async requestKoalaThrowable<T>(
    refreshableRequest: () => Promise<T>
  ): Promise<T> {
    // eslint-disable-next-line no-useless-catch
    try {
      return await refreshableRequest();
    } catch (e: any) {
      throw e;
    }
  }

  // Add Function that can throw error if failed fetch
  public static async requestKokattoThrowable<T>(
    refreshableRequest: () => Promise<T>
  ): Promise<T> {
    const result = await refreshableRequest();
    return result;
  }

  public static async requestProcat<T>(
    refreshableRequest: () => Promise<T> | Promise<ProcatErrorResponse>
  ): Promise<T | ProcatErrorResponse> {
    const response = await refreshableRequest();
    return response;
  }
}
