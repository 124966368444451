import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  showTemplateSubmiDisclaimer: boolean;
};

const state: State = {
  showTemplateSubmiDisclaimer: true,
};

const getters: GetterTree<State, any> = {
  getShowTemplateSubmiDisclaimer: (state) => state.showTemplateSubmiDisclaimer,
};

const mutations: MutationTree<State> = {
  updateShowTemplateSubmiDisclaimer(state, isShow: boolean) {
    state.showTemplateSubmiDisclaimer = isShow;
  },
};

const actions: ActionTree<State, any> = {
  setShowTemplateSubmiDisclaimer(context, isShow: boolean) {
    context.commit("updateShowTemplateSubmiDisclaimer", isShow);
  },
  clearShowTemplateSubmiDisclaimer(context) {
    context.commit("updateShowTemplateSubmiDisclaimer", true);
  },
};

const TemplateConfiguration = { state, getters, mutations, actions };

export default TemplateConfiguration;
