export function isInstaceOfValidator(
  validator: any
): validator is BaseValidator {
  if (!validator || typeof validator !== "object") return false;
  return validator instanceof BaseValidator;
}

export class BaseValidator {
  maxSize: number;
  allowedFormats: string[];

  constructor(maxSize: number, allowedFormats: string[]) {
    this.maxSize = maxSize;
    this.allowedFormats = allowedFormats;
  }

  validateSize(file: File) {
    return file.size <= this.maxSize;
  }

  validateFormat(file: File) {
    // We want to bypass the format checking by set empty allowedFormats
    if (this.allowedFormats.length === 0) return true;
    const fileExtension = file.name.split(".").pop() || "";
    return this.allowedFormats.includes(fileExtension);
  }

  validate(file: File) {
    return this.validateSize(file) && this.validateFormat(file);
  }
}

export class InstagramAudioValidator extends BaseValidator {
  constructor() {
    super(25 * 1024 * 1024, ["aac", "m4a", "wav", "mp3"]); // Max 25 MB, allowed formats
  }
}

export class InstagramImageValidator extends BaseValidator {
  constructor() {
    super(8 * 1024 * 1024, ["png", "jpeg", "gif"]); // Max 8 MB, allowed formats
  }
}

export class InstagramVideoValidator extends BaseValidator {
  constructor() {
    super(25 * 1024 * 1024, ["mp4", "ogg", "avi", "mov", "webm"]); // Max 25 MB, allowed formats
  }
}

export class WhatsappAudioValidator extends BaseValidator {
  constructor() {
    super(16 * 1024 * 1024, ["aac", "amr", "mp3", "m4a", "ogg"]); // Max 16 MB, allowed formats
  }
}

export class WhatsappDocumentValidator extends BaseValidator {
  constructor() {
    super(100 * 1024 * 1024, []); // Max 100 MB, allowed formats (currently we want to open any file extentions, so we leave it empty)
  }
}

export class WhatsappImageValidator extends BaseValidator {
  constructor() {
    super(5 * 1024 * 1024, ["jpeg", "jpg", "png"]); // Max 5 MB, allowed formats
  }
}

export class WhatsappVideoValidator extends BaseValidator {
  constructor() {
    super(16 * 1024 * 1024, ["3gp", "mp4"]); // Max 16 MB, allowed formats
  }
}
