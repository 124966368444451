const id = {
  createTemplateDetail: "Detail Template",
  createTemplateFormCampaignCode: "Kode campaign",
  createTemplateFormCampaignCodeBySystem: "Dapatkan dari sistem",
  createTemplateFormCampaignCodeSelfInput: "Buat sendiri",
  createTemplateFormCategory: "Kategori template",
  createTemplateFormCategorySubText:
    "Kategori template memudahkan dalam memilah jenis pesan untuk broadcast dan analisis.",
  createTemplateFormSender: "Nama dan alamat email pengirim",
  createTemplateFormSenderSubText:
    "Info pengirim broadcast yang ditampikan ke penerima",
  createTemplateFormSenderNamePlaceholder:
    "Masukkan nama pengirim. Contoh: Kokatto Team",
  createTemplateFormSenderEmailPlaceholder: "Masukkan alamat email",
  createTemplateFormSubjectPlaceholder: "Masukkan judul email",
  createTemplateFormSubject: "Subject email",
  createTemplateFormSubjectSubText:
    "Judul singkat akan muncul di inbox email penerima",
  createTemplateFormCampaignCodeSelfInputPlaceholder:
    "Masukkan kode campaign. Contoh: S01",
  createTemplateFormCampaignCodeExistError:
    "Kode sudah digunakan, silakan coba nomor lain",
  createTemplateFormSenderName: "Nama pengirim",
  createTemplateFormSenderEmail: "Email pengirim",
  createTemplateNextButton: "Selanjutnya",
  createTemplateFormSubjectEmailAliasError: "Tidak bisa lebih dari 60 karakter",
  createTemplateFormCampaignCodeError:
    "Kode campaign harus berjumlah 3 karakter",
  createTemplateCampaignCodeFailedGeneratingErrorMessage:
    "Gagal mendapatkan kode. Coba kembali",
  disableCreateButtonTooltip:
    "Domain email tidak tersedia. Silakan hubungi admin untuk mendapatkan domain email.",
  getProviderEmailFailedSnackbar: "Gagal mendapatkan data provider.",

  createTemplateContentEmail: "Konten Email",
  createTemplateContentEmailSubText:
    "Akan muncul dibagian paling awal dari email anda. Biasanya hanya diisi dengan gambar atau teks berukuran besar.",
  createTemplateEmailSuccessText: "Email template berhasil disimpan",
  createTemplateEmailErrorAlertDuplicate:
    "Kode campaign sudah pernah terdaftar. Silakan ganti kode campaign atau hapus template sebelumnya.",
  createTemplateEmailErrorRole:
    "Role tidak memiliki akses untuk membuat template.",
  createTemplateEmailErrorRequiredParameter:
    "Role tidak memiliki akses untuk membuat template.",
  createTemplateFailedText: "Gagal membuat email template",
  createTemplateEmailNextButton: "Selanjutnya",
  createTemplateEmailSaveButton: "Simpan template",
  createTemplateFailedUploadText: "Gagal upload file. Silakan coba lagi.",
  createTemplateContentEmailError: "Konten email wajib diisi",
  createTemplateContentEmailFormatError:
    "Variable tidak boleh mengandung spasi",

  createTemplatePreviewButton: "Preview Email",
  createTemplatePreview: "Preview template",
  createTemplatePreviewBackText: "Kembali",
  createTemplatePreviewToMe: "to me",
  createTemplatePreviewUnsubscribe: "Unsubscribe",
  createTemplateChangePreview: "Ubah Preview",

  getDetailTemplateFailedText:
    "Gagal mendapatkan detail template. Silakan coba lagi.",
  carousel: "Carousel",
  carouselCardNamePrefix: "Card {0}",
  carouselCardAdd: "Tambah",
  carouselMediaType: "Tipe media",
  carouselmediaTypeDescription:
    "Pilih media image atau video. Setiap card harus dengan media yang sama",
  carouselMediaTypePlaceholder: "Pilih media",
  carouselCardLabel: "Card carousel",
  carouselCardLimit: "Maksimal dapat menambahkan {0} card",
  carouselCardSubLabel: "Pastikan card terisi sebelum menambah baru",
  carouselCardSetting: "Pengaturan carousel",
  carouselCardSettingDescription:
    "Tambahkan media, pesan dan tombol di masing- masing card",
  carouselMediaTypeImage: "Gambar",
  carouselMediaTypeVideo: "Video",
  carouselButtonTypeUrlStatic: "Static",
  carouselButtonTypeUrlDynamic: "Dynamic",
  carouselButtonTypeCtaCallPhone: "Panggil nomor",
  carouselButtonTypeCtaVisitWebsite: "Kunjungi situs web",
  carouselCardAddMedia: "Upload gambar atau video",
  carouselCardMediaVideoNotSupported: "Browser anda tidak mendukung video",
  carouselCardMessageBubbleLabel: "Message bubble",
  carouselCardDynamicButtonAddButton: "Tambahkan tombol",
  carouselCardDynamicButtonAddButtonDescription:
    "Maksimun {0} tombol quick reply dan atau ajakan bertindak",
  carouselButtonTypeMissmatch: "Tipe tombol di setiap carousel harus sama",
  createTemplateCarouselEmptyCarouselSample:
    "Pastikan variabel contoh konten di setiap card terisi",
};

const en = {
  createTemplateDetail: "Template Detail",
  createTemplateFormCampaignCode: "Campaign Code",
  createTemplateFormCampaignCodeBySystem: "Generated by system",
  createTemplateFormCampaignCodeSelfInput: "Set manually",
  createTemplateFormCategory: "Template Category",
  createTemplateFormCategorySubText:
    "Email cateogry helps you in sorting out message type for broadcast and analysing.",
  createTemplateFormSender: "Sender name and email address",
  createTemplateFormSenderSubText:
    "Broadcast sender info displayed to the recipient",
  createTemplateFormSenderNamePlaceholder:
    "Enter sender name. Example: Kokatto Team",
  createTemplateFormSenderEmailPlaceholder: "Enter email address",
  createTemplateFormSubjectPlaceholder: "Enter email subject",
  createTemplateFormSubject: "Email subject",
  createTemplateFormSubjectSubText:
    "Brief title that appears in the recipient's email inbox",
  createTemplateFormCampaignCodeSelfInputPlaceholder:
    "Enter campaign code. Example: S01",
  createTemplateFormCampaignCodeExistError:
    "Code is already used, please use other numbers",
  createTemplateFormSenderName: "Sender name",
  createTemplateFormSenderEmail: "Email Address",
  createTemplateNextButton: "Next",
  createTemplateFormSubjectEmailAliasError: "Can't be more than 60 characters",
  createTemplateFormCampaignCodeError:
    "Campaign code must be exactly 3 characters",
  createTemplateCampaignCodeFailedGeneratingErrorMessage:
    "Failed to get code. Please try again",
  disableCreateButtonTooltip:
    "Email domain not available. Please contact the admin to obtain an email domain.",
  getProviderEmailFailedSnackbar: "Failed to load provider information.",

  createTemplateContentEmail: "Email Content",
  createTemplateContentEmailSubText:
    "This will appear at the very beginning of your email. It is usually filled with a large image or text.",
  createTemplateSuccessText: "Email template successfully saved.",
  createTemplateEmailErrorAlertDuplicate:
    "Campaign code is already registered. Please use a different campaign code or delete the previous template.",
  createTemplateEmailErrorRole:
    "Role does not have permission to create templates.",
  createTemplateFailedText: "Failed to create the email template.",
  createTemplateEmailNextButton: "Save",
  createTemplateEmailSaveButton: "Save template",
  createTemplateFailedUploadText: "Failed to upload file. Please try again.",
  createTemplateContentEmailError: "Email content is required",
  createTemplateContentEmailFormatError: "Variable cannot contain space",

  createTemplatePreviewButton: "Preview Email",
  createTemplatePreview: "Preview template",
  createTemplatePreviewBackText: "Back",
  createTemplatePreviewToMe: "to me",
  createTemplatePreviewUnsubscribe: "Unsubscribe",
  createTemplateChangePreview: "Change Preview",

  getDetailTemplateFailedText:
    "Failed to get template detail. Please try again",
  carousel: "Carousel",
  carouselCardNamePrefix: "Card {0}",
  carouselCardAdd: "Add",
  carouselMediaType: "Media type",
  carouselmediaTypeDescription:
    "Choose image or video. Every cards can need to have the same media type",
  carouselMediaTypePlaceholder: "Choose media",
  carouselCardLabel: "Carousel card",
  carouselCardLimit: "You can add up to {0} cards",
  carouselCardSubLabel: "Ensure each card is complete before adding another",
  carouselCardSetting: "Carousel setting",
  carouselCardSettingDescription: "Add media, message and button on each cards",
  carouselMediaTypeImage: "Image",
  carouselMediaTypeVideo: "Video",
  carouselButtonTypeUrlStatic: "Static",
  carouselButtonTypeUrlDynamic: "Dynamic",
  carouselButtonTypeCtaCallPhone: "Call phone number",
  carouselButtonTypeCtaVisitWebsite: "Visit website",
  carouselCardAddMedia: "Upload image or video",
  carouselCardMediaVideoNotSupported:
    "Your browser does not support the video tag",
  carouselCardMessageBubbleLabel: "Message bubble",
  carouselCardDynamicButtonAddButton: "Add buttons",
  carouselCardDynamicButtonAddButtonDescription:
    "Add up to {0} quick reply and/or call to action buttons",
  carouselButtonTypeMissmatch:
    "Button types must be consistent across all carousel cards",
  createTemplateCarouselEmptyCarouselSample:
    "Ensure variable sample content is filled in each card",
};

export default { id, en };
