const id = {
  userConsentTitle: "Persetujuan Pengguna",
  userConsentTextApproval: "Setuju",
  userConsentTextDismiss: "Kembali",
};
const en = {
  userConsentTitle: "User Consent",
  userConsentTextApproval: "Agree",
  userConsentTextDismiss: "Back",
};

export default { id, en };
